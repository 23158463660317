import buys from './buys.service';
import invoices from './invoices.service';
import sales from './sales.service';
import settings from './settings.service';
import terminates from './terminates.service';
import room_state from './room-states.service';

export default {
  buys,
  invoices,
  sales,
  settings,
  terminates,
  room_state,
};
