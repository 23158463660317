import React, { useCallback, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import api from 'generic/api/guests/v1';
import { toast } from 'generic/services/globals';
import { IButton } from 'components/buttons/v2';
import { Flex } from 'components/grids/v2';
import { LinearListOption, GenericListItem } from 'components/lists/v1';

import './RoomRetains.scss';

const RoomRetains = ({ booking }) => {
  const [items, set_items] = useState(booking.retains ? booking.retains.map(
    (retain) => ({ date: moment(retain.date).toDate(), total: retain.total }),
  ) : [] || []);
  const [total, set_total] = useState(0);
  const [date] = useState(new Date());

  const on_create_select = () => {
    items.push({
      date,
      total,
    });
    set_items([...items]);
    api.bookings.update({ _id: booking._id, retains: items })
    .catch(toast.api_danger);
  };

  const on_local_number_change = (event) => {
    const aux_price = parseInt(event.target.value, 10);
    set_total(aux_price);
  };

  const on_select = useCallback(() => {
  }, []);

  const on_select_delete = useCallback((item) => {
    items.splice(item._id, 1);
    set_items([...items]);
    api.bookings.update({ _id: booking._id, retains: items })
    .catch(toast.api_danger);
  }, [booking, items, set_items]);

  const render_options = (item) => (
    <IButton label="Quitar" icon="delete" on_click={() => on_select_delete(item)} />
  );

  const render_visitors = (data) => data.map((item, index) => ({
    _id: index,
    name: `${item.total}`,
    description: moment(item.date).format('MM/DD/YYYY LT'),
  }));

  return (
    <div>
      <Flex direction={Flex.V}>
        <Flex.Item>
          <h2>Adelantos de pago</h2>
          <LinearListOption
            items={render_visitors(items)}
            on_select={on_select}
            options={render_options}
          />
        </Flex.Item>
        <Flex.Item>
          <GenericListItem>
            <GenericListItem.Text name={`TOTAL ${items.reduce((sum, item) => (sum + item.total), 0)} Bs.`} />
            {/* <GenericListItem.Text name={moment(date).format('MM/DD/YYYY LT')} /> */}
            <GenericListItem.Item />
            <GenericListItem.Number>
              <Input placeholder="Precio...">
                <input name="price" type="number" placeholder="Cantidad" value={total} onChange={on_local_number_change} />
              </Input>
            </GenericListItem.Number>
            <IButton label="Registrar adelanto" icon="checkmark" on_click={on_create_select} />
          </GenericListItem>
        </Flex.Item>
      </Flex>
    </div>
  );
};

RoomRetains.propTypes = {
  booking: PropTypes.any.isRequired,
};

export { RoomRetains };
