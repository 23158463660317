import React, { useCallback, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import api from 'generic/api/guests/v2';
import { Header, EmptyInfo } from 'components/titles/v2';
import { toast } from 'generic/services/globals';
import { Flex, Container } from 'components/grids/v3';

import { List } from './list/List';
import { generate_file_v2 } from '../utils/excel';
import { DateSelector } from '../utils/DateSelector';

const MaintenanceHistory = ({ area }) => {
  const [items, set_items] = useState([]);

  const header = [
    'ENTRADA',
    'SALIDA',
    'DURACION',
    'TIPO',
    'REQUERIDO',
    'COMPLETADO',
    'DESCRIPCION',
  ];

  const parse_data = () => (
    items.map((item) => ({
      start: moment(item.start).format('MM/DD/YYYY LT'),
      end: moment(item.end).format('MM/DD/YYYY LT'),
      duration: item.duration,
      type: item.type,
      requester_name: item.requester_name,
      completer_name: item.completer_name,
      description: item.description,
    }))
  );

  const on_download = () => {
    generate_file_v2(header, parse_data(), 'mantenimiento', 'mantenimiento');
  };

  const on_search = useCallback((range) => {
    api.room_state.report({ area_id: area._id, ...range })
    .then(({ data }) => {
      set_items(data);
    })
    .catch(toast.api_danger);
  }, [area]);

  return (
    <Flex direction={Flex.V}>
      <Container width="1200px">
        <Header title="Historial de mantenimiento de las habitaciones" />
        <DateSelector on_search={on_search} on_download={on_download} />
        <Flex.Item>
          {(items.length === 0) && <EmptyInfo description="No existe visitantes en el hotel" />}
          {(items.length > 0) && <List items={items} on_select={() => {}} />}
        </Flex.Item>
      </Container>
    </Flex>
  );
};

MaintenanceHistory.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { MaintenanceHistory };
