import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';

import api from 'generic/api/guests/v1';
import { GENERIC } from 'consts';
import { toast } from 'generic/services/globals';
import { Header, EmptyInfo } from 'components/titles/v2';
import actions from 'actions/guests';
import { Flex } from 'components/grids/v4';
import { ChipList } from 'components/lists/v1';
import { loader } from 'generic/services/globals/v2';

import { TITLE } from './constants';
import { List } from './list/List';

const Rooms = ({ area }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [items, set_items] = useState([]);
  const [rooms, set_rooms] = useState([]);
  const [room_type, set_room_type] = useState({
    _id: null,
  });
  const [room_types, set_room_types] = useState([]);

  const on_select = useCallback((group) => {
    history.push(`${GENERIC.APP_BASE_URL}/main/${group._id}`);
  }, [history]);

  const on_room_type_select = useCallback((item) => {
    set_room_type(item);
    set_items(rooms.filter((room) => item._id === null || room.room_type_id === item._id));
  }, [rooms]);

  useEffect(() => {
    loader.resolve()
    .then(loader.start)
    .then(() => api.rooms.find({ area_id: area._id }))
    .then(({ data }) => {
      set_rooms(data);
      set_items([...data]);
    })
    .then(loader.stop)
    .catch(loader.error)
    .catch(toast.api_danger);

    api.room_types.find({ area_id: area._id })
    .then(({ data }) => {
      set_room_types(data.map((type) => ({ ...type, name: `${type.name} (${type.price}Bs.)` })));
    })
    .catch(toast.api_danger);
    ReactGA.event({
      category: 'rooms',
      action: 'list',
    });
  }, [area]);

  useEffect(() => {
    dispatch(actions.main.set_booking(null));
  }, [dispatch]);

  return (
    <Flex direction={Flex.V}>
      <Header title={TITLE} />
      <ChipList current={room_type} items={room_types} on_handle_click={on_room_type_select} />
      <Flex.Item>
        {(items.length === 0) && <EmptyInfo description="No existe habitaciones de este tipo en el hotel" />}
        <List items={items} on_select={on_select} />
      </Flex.Item>
    </Flex>
  );
};

Rooms.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { Rooms };
