import axios from 'axios';

import { BASE_PATH } from 'consts';
import auth from './auth.service';

const upload_images = (file, on_upload_progress) => {
  const data = new FormData();
  data.append('avatar', file);
  return axios.create(auth.header()).post(`${BASE_PATH.FILES}/v1/assets/images`, data, {
    on_upload_progress,
  });
};

export default { upload_images };
