import { StorageService } from 'generic/services/globals';

import { types as auth_types } from 'actions/globals/v1/auth.actions';
import { types as account_types } from 'actions/globals/v1/accounts.actions';

const initialState = {
  loading: false,
  account: null,
  token: StorageService.load('token'),
};

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
  case account_types.ACCOUNT_SET: {
    // const { token } = data;
    const session = data;
    delete session.token;
    StorageService.save('account', session);
    // StorageService.save('token', token);
    return {
      ...state,
      account: data,
      // token,
    };
  }
  case account_types.ACCOUNT_LOAD: {
    StorageService.save('account', data.account);
    return {
      ...state,
      loading: data.loading,
      account: data.account,
    };
  }
  case auth_types.LOGIN: {
    const { token } = data;
    const session = data;
    delete session.token;
    StorageService.save('account', session);
    StorageService.save('token', token);
    return {
      ...state,
      account: data,
      token,
    };
  }
  case auth_types.LOGOUT: {
    StorageService.remove('drawer');
    StorageService.remove('checkout-config');
    // StorageService.remove('area');
    StorageService.remove('account');
    StorageService.remove('token');
    return {
      ...state,
      account: null,
      token: null,
    };
  }
  default:
    return state;
  }
};

export default reducer;
