import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import api from 'generic/api/guests/v1';
import { toast } from 'generic/services/globals';
import { Flex } from 'components/grids/v2';
import { ProductList } from '../list/ProductList';
import { calc_days, calc_products } from '../room_builder';

import './RoomProducts.scss';

const RoomProducts = ({ booking }) => {
  const { rooms, room_types } = useSelector((state) => state.main);

  const [otros, set_otros] = useState({ ...booking.otros });
  const [room_ids] = useState([...booking.room_ids]);
  const [amount, set_amount] = useState(0);

  // eslint-disable-next-line
  const on_product_update = useCallback((room_id, price) => {
    const aux_otro = { ...otros, [room_id]: price };
    set_otros(aux_otro);

    api.bookings.update({ _id: booking._id, otros: aux_otro })
    .catch(toast.api_danger);
  }, [booking, otros]);

  useEffect(() => {
    set_amount(calc_days(booking));
  }, [booking]);

  return (
    <Flex direction={Flex.V}>
      <Flex.Item>
        <Flex direction={Flex.V}>
          <Flex.Item>
            <h2>Habitaciones</h2>
            <ProductList
              items={calc_products(
                room_ids,
                otros,
                amount,
                rooms,
                room_types,
              )}
              amount={amount}
              on_update={on_product_update}
            />
          </Flex.Item>
        </Flex>
      </Flex.Item>
    </Flex>
  );
};

RoomProducts.propTypes = {
  booking: PropTypes.any.isRequired,
};

export { RoomProducts };
