import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import global_api from 'generic/api/globals/v1';
import api from 'generic/api/guests/v1';
import global_actions from 'actions/globals/v1';
import actions from 'actions/guests';
import { toast, StorageService } from 'generic/services/globals';

const Session = () => {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.auth);

  const load_area_setting = useCallback((tmp_area) => {
    api.settings.get_by_id(tmp_area._id)
    .then(({ data }) => {
      dispatch(actions.area.create_setting(data));
    })
    .catch(toast.api_danger);
  }, [dispatch]);

  useEffect(() => {
    if (!account) return;

    global_api.areas.find({})
    .then(({ data }) => {
      const stored_area = StorageService.load('area');
      let tmp_area = data[0];
      if (stored_area) {
        tmp_area = data.find((item) => item._id === stored_area._id) || tmp_area;
      }
      dispatch(global_actions.areas.set(data));
      if (!tmp_area) return;

      load_area_setting(tmp_area);
      dispatch(global_actions.apps.set_area(tmp_area));
    })
    .catch(toast.api_danger);
  }, [dispatch, load_area_setting, account]);

  return null;
};

export { Session };
