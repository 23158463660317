import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'semantic-ui-react';

import api from 'generic/api/guests/v1';
import { useFormReducer } from 'generic/functions/globals/reducer';
import { toast } from 'generic/services/globals';
import { IButton, ContentTitle } from 'components';
import { AREA_UPDATE_TITLE } from '../setting.constants';

const SettingOrder = ({ area }) => {
  const {
    item,
    set_item,
    on_string_change,
    on_number_change,
    on_checkbox_change,
  } = useFormReducer({});

  const handle_submit = (e) => {
    e.preventDefault();
    api.settings.update(area._id, item)
    .then(() => {
      toast.success('se actualizo correctamente');
    })
    .catch(toast.api_danger);
  };

  useEffect(() => {
    api.settings.get_by_id(area._id)
    .then(({ data }) => {
      set_item({
        order_counter: data.order_counter || 100,
        order_nim: data.order_nim || 100,
        order_max: data.order_max || 999,
        order_prefix: data.order_prefix || 'H',
        order_suffix: data.order_suffix || '',
        order_restart: data.order_restart || true,
      });
    })
    .catch(toast.api_danger);
  }, [set_item, area]);

  return (
    <>
      <ContentTitle config={AREA_UPDATE_TITLE} />
      <Form onSubmit={handle_submit} inverted>
        <Form.Field>
          <label>Contador ordenes</label>
          <input name="order_counter" type="number" placeholder="Contador ordenes" value={item.order_counter} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Numero de orden inicial</label>
          <input name="order_nim" type="number" placeholder="Numero de orden inicial" value={item.order_nim} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Numero de orden limite</label>
          <input name="order_max" type="number" placeholder="Numero de orden limite" value={item.order_max} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Prefijo</label>
          <input name="order_prefix" placeholder="Prefijo" value={item.order_prefix} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Sufijo</label>
          <input name="order_suffix" placeholder="Sufijo" value={item.order_suffix} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <Checkbox name="order_restart" checked={item.order_restart} label="¿Reiniciar cada dia?" onChange={on_checkbox_change} />
        </Form.Field>
        <IButton label="Actualizar información" icon="checkmark" submit />
      </Form>
    </>
  );
};

SettingOrder.propTypes = {
  area: PropTypes.any.isRequired,
};

export { SettingOrder };
