import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Flex } from 'components/grids/v3';

const ListItem = ({ item, on_select }) => {
  const on_click = (e) => {
    e.stopPropagation();
    on_select(item);
  };

  return (
    <Flex.Scrap class_name="report-list-item" onClick={on_click}>
      <Flex direction={Flex.H}>
        {/* <Flex.Item flex={2}>
          {`${moment(item.start).format('MM/DD/YYYY LT')}`}
        </Flex.Item> */}
        <Flex.Item flex={2}>
          {`${moment(item.end).format('MM/DD/YYYY LT')}`}
        </Flex.Item>
        <Flex.Item flex={1}>
          {item.duration}
        </Flex.Item>
        <Flex.Item flex={2}>
          {item.type}
        </Flex.Item>
        <Flex.Item flex={2}>
          {item.requester_name}
        </Flex.Item>
        <Flex.Item flex={2}>
          {item.completer_name}
        </Flex.Item>
        <Flex.Item flex={4}>
          {item.description}
        </Flex.Item>
      </Flex>
    </Flex.Scrap>
  );
};

ListItem.propTypes = {
  item: PropTypes.any.isRequired,
  on_select: PropTypes.func.isRequired,
};

export { ListItem };
