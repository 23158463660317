import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { FormContainer, Flex } from 'components/grids/v2';
import { Room } from './Room';
import { Rooms } from './Rooms';
import { RoomEdit } from './RoomEdit';

const RoomsRoute = ({ area }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Rooms area={area} />
      </Route>
      <Route exact path={`${path}/new`}>
        <Flex.Center>
          <FormContainer>
            <RoomEdit area={area} />
          </FormContainer>
        </Flex.Center>
      </Route>
      <Route exact path={`${path}/:room_id/edit`}>
        <Flex.Center>
          <FormContainer>
            <RoomEdit area={area} />
          </FormContainer>
        </Flex.Center>
      </Route>
      <Route exact path={`${path}/:room_id`}>
        <Flex.Center>
          <FormContainer>
            <Room area={area} />
          </FormContainer>
        </Flex.Center>
      </Route>
    </Switch>
  );
};

RoomsRoute.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { RoomsRoute };
