export const types = {
  MAIN_SET: 'main-set',
  MAIN_SET_ONE: 'main-set-one',
  MAIN_UPDATE: 'main-update',
  MAIN_REMOVE: 'main-remove',
  MAIN_SET_BOOKING: 'main-set-booking',
  MAIN_SET_ROOMS: 'main-set-rooms',
  MAIN_SET_ROOM_TYPES: 'main-set-room-types',
};

const set = (data) => ({
  type: types.MAIN_SET,
  data,
});

const set_one = (data) => ({
  type: types.MAIN_SET_ONE,
  data,
});

const update = (data) => ({
  type: types.MAIN_UPDATE,
  data,
});

const remove = (data) => ({
  type: types.MAIN_REMOVE,
  data,
});

const set_booking = (data) => ({
  type: types.MAIN_SET_BOOKING,
  data,
});

const set_rooms = (data) => ({
  type: types.MAIN_SET_ROOMS,
  data,
});

const set_room_types = (data) => ({
  type: types.MAIN_SET_ROOM_TYPES,
  data,
});

export default { set, set_one, update, remove, set_booking, set_rooms, set_room_types };
