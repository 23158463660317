import { types } from 'actions/globals/v2/sensors.actions';

const initial_state = {
  sensors: [],
};

const reducer = (state = initial_state, { type, data }) => {
  switch (type) {
  case types.GROUPS_SET: {
    return { ...state, sensors: [...data] };
  }
  case types.GROUPS_SET_ONE: {
    return { ...state, sensors: [data, ...state.sensors] };
  }
  case types.GROUPS_UPDATE: {
    const sensors = state.sensors.map((item) => (item._id === data._id ? { ...data } : item));
    return { ...state, sensors };
  }
  case types.GROUPS_REMOVE: {
    const sensors = state.sensors.filter((item) => item._id !== data._id);
    return { ...state, sensors };
  }
  default:
    return state;
  }
};

export default reducer;
