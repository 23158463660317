import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { IButton } from 'components/buttons/v2';
import { files } from 'generic/services/globals';
import { BASE_PATH } from 'consts';

import './UImages.scss';

const DIRECTION = {
  H: 'width',
  V: 'height',
};

const UImages = ({ ids, width, height, direction, on_callback }) => {
  const [items, set_items] = useState([]);
  const style = {
    width,
    height,
  };

  const on_select = (element) => {
    const tmp_ids = items.filter((item) => item !== element);

    set_items(tmp_ids);
    on_callback(tmp_ids);
  };

  const on_image_select = (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];

      files.upload_images(img, (chuck) => {
        /* eslint-disable no-console */
        console.log('uploading..', Math.round((100 * chuck.loaded) / chuck.total));
      })
      .then(({ data }) => {
        /* eslint-disable no-console */
        console.log(data);

        const tmp_ids = [...items, data._id];

        set_items(tmp_ids);
        on_callback(tmp_ids);
      })
      .catch((err) => {
        /* eslint-disable no-console */
        console.log(err);
      });
    }
  };

  useEffect(() => {
    set_items([...ids]);
  }, [ids]);

  return (
    <div className="v2-u-images">
      <label>Seleccionar varias imagenes</label>
      {
        items.map((item) => (
          <div key={item}>
            <div className="image" style={style}>
              <img alt="" src={`${BASE_PATH.FILES}/v1/assets/images/${direction}/1080/${item}`} />
            </div>
            <IButton label="Eliminar" icon="cancel" on_click={() => on_select(item)} />
          </div>
        ))
      }
      <input type="file" name="avatar" onChange={on_image_select} />
    </div>
  );
};

UImages.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.any),
  width: PropTypes.string,
  height: PropTypes.string,
  direction: PropTypes.string,
  on_callback: PropTypes.func.isRequired,
};

UImages.defaultProps = {
  ids: [],
  width: 'auto',
  height: 'auto',
  direction: DIRECTION.H,
};

UImages.H = DIRECTION.H;
UImages.V = DIRECTION.V;

export { UImages };
