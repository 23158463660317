import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';

import actions from 'actions/globals/v1';
import api from 'generic/api/globals/v1';
import { toast } from 'generic/services/globals';

import { IButton, ContentTitle } from 'components';
import { UImage, UImages } from 'components/images/v2';
import { useFormReducer } from 'generic/functions/globals/reducer';
import { AREA_UPDATE_TITLE } from '../areas.constants';

const HomeAreaUpdate = ({ area }) => {
  const dispatch = useDispatch();
  const {
    item,
    set_item,
    on_string_change,
  } = useFormReducer({
    _id: area._id,
    name: area.name,
    image_id: area.image_id,
    description: area.description,
    image_ids: area.image_ids,
    code: area.code,
  });

  const handle_submit = (e) => {
    e.preventDefault();
    api.areas.update(item)
    .then(({ data }) => {
      dispatch(actions.apps.set_area(data));
      toast.success('se actualizo correctamente');
    })
    .catch(toast.api_danger);
  };

  const handle_image = useCallback((data) => {
    set_item({ ...item, ...data });
  }, [set_item, item]);

  const handle_images = useCallback((data) => {
    set_item({ ...item, image_ids: data });
  }, [set_item, item]);

  return (
    <>
      <ContentTitle config={AREA_UPDATE_TITLE} />
      <Form onSubmit={handle_submit} inverted>
        <Form.Field>
          <label>Code</label>
          <input name="code" placeholder="Code" value={item.code} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Nombre</label>
          <input name="name" placeholder="Nombre" value={item.name} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Descripción</label>
          <textarea name="description" placeholder="Descripción" value={item.description} onChange={on_string_change} />
        </Form.Field>

        <UImage image={item.image_id} height="150px" quality={1080} on_callback={handle_image} />
        <UImages ids={item.image_ids} width="100%" height="100px" quality={1080} on_callback={handle_images} />
        <IButton label="Actualizar area" icon="checkmark" submit />
      </Form>
    </>
  );
};

HomeAreaUpdate.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
    image_ids: PropTypes.arrayOf(PropTypes.string),
    code: PropTypes.string,
  }).isRequired,
};

export { HomeAreaUpdate };
