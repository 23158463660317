import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Statistic } from 'semantic-ui-react';

import { GENERIC } from 'consts';
import api from 'generic/api/guests/v2';
import { Header, EmptyInfo } from 'components/titles/v2';
import { toast } from 'generic/services/globals';
import { Flex, Container } from 'components/grids/v3';

import { IRoomButton } from '../../main/helper/IRoomButton';
import { generate_file } from '../utils/excel';
import { DateSelector } from '../utils/DateSelector';
import { List } from './list/List';

const BuysHistory = ({ area }) => {
  const history = useHistory();
  const [totals, set_totals] = useState({
    sale: 0,
  });
  const [items, set_items] = useState([]);

  const parse_data = () => (
    items.map((item) => ({
      index: item.index,
      fecha: moment(item.created).format('MM/DD/YYYY LT'),
      encargado: item.account_name,
      name: item.name,
      description: item.description,
      price: item.price,
      amount: item.amount,
      total: item.price * item.amount,
      saldado: item.closed,
    }))
  );

  const on_search = useCallback((range) => {
    api.buys.reports({ area_id: area._id, ...range })
    .then(({ data }) => {
      set_items(data);
      set_totals({
        sale: data.reduce((total, item) => total + (item.price * item.amount), 0),
      });
    })
    .catch(toast.api_danger);
  }, [area]);

  const on_download = () => {
    generate_file(parse_data(), 'compras', 'compras');
  };

  const on_select = useCallback((item) => {
    history.push(`${GENERIC.APP_BASE_URL}/buys/${item._id}`);
  }, [history]);

  const on_description_callback = useCallback(() => {
    history.push(`${GENERIC.APP_BASE_URL}/buys/create`);
  }, [history]);

  return (
    <Flex.Item>
      <Container width="1100px">
        <Flex direction={Flex.V}>
          <IRoomButton label="Registrar compra" icon="checkmark" color="warn" on_click={on_description_callback} />
          <Header title="Compras registradas" />
          <Flex.Scrap>
            <Flex direction={Flex.H}>
              <Statistic label="Total de compras ultimos 7 dias" value={totals.sale} size="tiny" inverted />
              <Flex direction={Flex.V}>
                <DateSelector on_search={on_search} on_download={on_download} />
              </Flex>
            </Flex>
          </Flex.Scrap>
          <Flex.Item>
            {(items.length === 0) && <EmptyInfo description="No existe compras registradas este dia" />}
            {(items.length > 0) && <List items={items} on_select={on_select} />}
          </Flex.Item>
        </Flex>
      </Container>
    </Flex.Item>
  );
};

BuysHistory.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { BuysHistory };
