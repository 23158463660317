import { types } from 'actions/globals/v1/groups.actions';

const initial_state = {
  groups: [],
};

const reducer = (state = initial_state, { type, data }) => {
  switch (type) {
  case types.GROUPS_SET: {
    return { ...state, groups: [...data] };
  }
  case types.GROUPS_SET_ONE: {
    return { ...state, groups: [data, ...state.groups] };
  }
  case types.GROUPS_UPDATE: {
    const groups = state.groups.map((item) => (item._id === data._id ? { ...data } : item));
    return { ...state, groups };
  }
  case types.GROUPS_REMOVE: {
    const groups = state.groups.filter((item) => item._id !== data._id);
    return { ...state, groups };
  }
  default:
    return state;
  }
};

export default reducer;
