import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';

import reportWebVitals from './reportWebVitals';

import App from './App';
import { store } from './reducers';

import 'semantic-ui-css/semantic.min.css';
import 'react-notifications-component/dist/theme.css';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import './index.css';

ReactGA.initialize('G-Q0V316S0J8');

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
