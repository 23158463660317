export const CONTEXT = 'guests';
export const TITLE = 'lista de habitaciones';
export const ITEM_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'area de creacion y edicion de habitaciones',
};
export const ITEMS_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'lista de habitaciones',
};

export const ADD_NEW_ITEM = 'Crear nueva habitación';
