import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { GENERIC } from 'consts';
import { LinearList } from 'components/lists/v1';

const RoomToolbar = ({ booking, rooms }) => {
  const history = useHistory();

  const render_curtomer = () => {
    if (!booking) return null;
    if (!booking.customer) return null;

    return (
      <div>
        <div className="customer-information">
          <div>
            {`CI/NIT: ${booking.customer.customer_index}`}
          </div>
          <div>
            {`NOMBRE: ${booking.customer.customer_name}`}
          </div>
        </div>
      </div>
    );
  };

  const on_select_room = useCallback((select_room) => {
    history.push(`${GENERIC.APP_BASE_URL}/main/${select_room._id}`);
  }, [history]);

  const render_rooms = () => (
    <LinearList
      items={booking.room_ids.map((x) => rooms.find((y) => y._id === x))}
      on_select={on_select_room}
    />
  );

  return (
    <>
      {render_curtomer()}
      {booking && render_rooms()}
    </>
  );
};

RoomToolbar.propTypes = {
  booking: PropTypes.shape(PropTypes.any).isRequired,
  rooms: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export { RoomToolbar };
