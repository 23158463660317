import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'components/grids/v4';
import { Image } from 'components/images/v2';
// import $ from 'jquery';

import './Header.scss';

const Header = ({ area }) => (
  <Flex.Scrap height="400px" class_name="h-header">
    <body id="page-top" data-spy="scroll" data-target=".navbar-fixed-top">
      <div style={{ alignSelf: 'center', width: '300px', height: '100%', margin: 'auto', position: 'relative' }}>
        <div style={{ position: 'absolute', width: '100%', height: '98%' }}>
          <Image width="300px" height="100%" image={area.image_id} direction={Image.H} quality={450} />
        </div>
        <div style={{ position: 'absolute', width: '100%', height: '98%', background: '#00000059' }} />
        <div className="h-title">
          <p>{area.name}</p>
          <p>{`@${area.code}`}</p>
        </div>
      </div>
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <a className="navbar-brand page-scroll" href="#page-top">Touché</a>
          </div>
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul className="nav navbar-nav navbar-right">
              <li><a href="#about" className="page-scroll">About</a></li>
              <li><a href="#restaurant-menu" className="page-scroll">Menu</a></li>
              <li><a href="#portfolio" className="page-scroll">Gallery</a></li>
              <li><a href="#team" className="page-scroll">Chefs</a></li>
              <li><a href="#call-reservation" className="page-scroll">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="intro-text">
                  <h1>Touché</h1>
                  <p>Restaurant / Coffee / Pub</p>
                  <a href="#about" className="btn btn-custom btn-lg page-scroll">Discover Story</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6 ">
              <div className="about-img">
                <img src="img/about.jpg" className="img-responsive" alt="" />
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>Our Restaurant</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Duis sed dapibus leo nec ornare diam. Sed commodo nibh ante facilisis
                  bibendum dolor feugiat at. Duis sed dapibus leo nec ornare diam commodo nibh.
                </p>
                <h3>Awarded Chefs</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Duis sed dapibus leo nec ornare diam. Sed commodo nibh ante facilisis
                  bibendum dolor feugiat at. Duis sed dapibus leo nec ornare.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="restaurant-menu">
        <div className="section-title text-center center">
          <div className="overlay">
            <h2>Menu</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed.</p>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="menu-section">
                <h2 className="menu-section-title">Breakfast & Starters</h2>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $35 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $30 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $30 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $30 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6">
              <div className="menu-section">
                <h2 className="menu-section-title">Main Course</h2>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $45 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $30 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $30 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $30 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="menu-section">
                <h2 className="menu-section-title">Dinner</h2>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $45 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $350 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $30 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam.. </div>
                </div>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $30 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6">
              <div className="menu-section">
                <h2 className="menu-section-title">Coffee & Drinks</h2>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $35 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $30 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $30 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
                <div className="menu-item">
                  <div className="menu-item-name"> Delicious Dish </div>
                  <div className="menu-item-price"> $30 </div>
                  <div className="menu-item-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, duis sed dapibus leo nec ornare diam. </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </Flex.Scrap>
);

Header.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { Header };
