import { createStore, combineReducers } from 'redux';

// CORE IMPORTS
import apps from './globals/v1/apps.reducers';
import areas from './globals/v1/areas.reducers';
import auth from './globals/v1/auth.reducers';
import groups from './globals/v1/groups.reducers';
import loading from './globals/v1/loading.reducers';

import sensors from './globals/v2/sensors.reducers';

import area from './guests/area.reducers';
import categories from './guests/categories.reducers';
import main from './guests/main.reducers';

const reducer = combineReducers({
  apps,
  areas,
  auth,
  groups,
  loading,
  sensors,

  area,
  categories,
  main,
});

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export { store };
