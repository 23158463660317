import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import api from 'generic/api/guests/v1';
import actions from 'actions/guests';
import { toast } from 'generic/services/globals';

import { Room } from './Room';
import { Rooms } from './Rooms';
import { Checkout } from './checkout/Checkout';
import { Invoices } from './invoices/Invoices';

const MainRouter = ({ area }) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  useEffect(() => {
    api.rooms.find_original({ area_id: area._id })
    .then(({ data }) => {
      dispatch(actions.main.set_rooms(data));
    })
    .catch(toast.api_danger);
    api.room_types.find({ area_id: area._id })
    .then(({ data }) => {
      dispatch(actions.main.set_room_types(data));
    })
    .catch(toast.api_danger);
  }, [dispatch, area]);

  return (
    <Switch>
      <Route exact path={path}>
        <Rooms area={area} />
      </Route>
      <Route exact path={`${path}/booking/:booking_id/checkout`}>
        <Checkout area={area} />
      </Route>
      <Route exact path={`${path}/sales/:sale_id/invoices`}>
        <Invoices />
      </Route>
      <Route exact path={`${path}/:room_id`}>
        <Room area={area} />
      </Route>
    </Switch>
  );
};

MainRouter.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { MainRouter };
