import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import api from 'generic/api/guests/v1';
import { GENERIC } from 'consts';
import { toast } from 'generic/services/globals';
import { FormLabels } from 'components/forms/v1';
import { HImage } from 'components';
import { ModelTitle } from 'components/titles/v2';
import { IButton } from 'components/buttons/v2';
import { Flex } from 'components/grids/v2';
import { CONTEXT } from './constants';

const Room = () => {
  const { room_id } = useParams();
  const history = useHistory();
  const [item, set_item] = useState({
    accounts: [],
  });

  const on_update = () => {
    history.push(`${GENERIC.APP_BASE_URL}/rooms/${item._id}/edit`);
  };

  useEffect(() => {
    api.rooms.get_by_id(room_id)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [room_id, set_item]);

  return (
    <FormLabels>
      <ModelTitle context={CONTEXT} title={item.name} description={item.description} />
      <Flex.End>
        <IButton label="Actualizar habitacion" icon="edit" on_click={on_update} />
      </Flex.End>
      <HImage image={item.image_id} height="150px" quality={1080} />
    </FormLabels>
  );
};

export { Room };
