import React from 'react';

import { Screen } from 'components/screens/v1';

const Search = () => (
  <Screen>
    Buscador
  </Screen>
);

export { Search };
