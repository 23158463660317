import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { Screen } from 'components/screens/v1';
import { Flex } from 'components/grids/v4';
import { Session } from '../session/Session';
import { Configuration } from './configuration/Configuration';
import { Header } from '../header/Header';
import { Menu } from '../header/Menu';

import { RoomsRoute } from './rooms/RoomsRoute';
import { RoomtypesRoute } from './room_types/RoomTypesRoute';
import { MainRouter } from './main/MainRoute';

import { Buys } from './reports/buys/Buys';
import { CheckoutHistory } from './reports/checkout/CheckoutHistory';
import { InvoicesHistory } from './reports/invoices/InvoicesHistory';
import { PeopleHistory } from './reports/people/PeopleHistory';
import { SalesHistory } from './reports/sales/SalesHistory';
import { Terminates } from './reports/terminates/Terminates';
import { VisitorsHistory } from './reports/visitors/VisitorsHistory';
import { MaintenanceHistory } from './reports/maintenance/MaintenanceHistory';
import { TutorialHistory } from './tutorials/TutorialHistory';

import { Accounts } from './accounts/Accounts';
import { Settings } from './settings/Settings';

import './Guests.scss';

const Guests = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { area } = useSelector((state) => state.apps);
  const { loading, account } = useSelector((state) => state.auth);

  const [item, set_item] = useState(null);

  useEffect(() => {
    if (!area) return;

    set_item(area);
  }, [dispatch, area]);

  useEffect(() => {
    if (loading && !account) {
      history.push('/login');
    }
  }, [history, loading, account]);

  const render_sales = () => (
    <Flex.Item>
      <Switch>
        <Route path={`${path}/main`}>
          <MainRouter area={item} />
        </Route>
        <Route path={`${path}/people`}>
          <PeopleHistory area={item} />
        </Route>
        <Route path={`${path}/maintenance`}>
          <MaintenanceHistory area={item} />
        </Route>
        <Route path={`${path}/visitors`}>
          <VisitorsHistory area={item} />
        </Route>
        <Route path={`${path}/sales`}>
          <SalesHistory area={item} />
        </Route>
        <Route path={`${path}/buys`}>
          <Buys area={item} />
        </Route>
        <Route path={`${path}/checkout`}>
          <CheckoutHistory area={item} />
        </Route>
        <Route path={`${path}/settings`}>
          <Settings area={item} />
        </Route>
        <Route path={`${path}/configuration`}>
          <Configuration />
        </Route>
        <Route path={`${path}/rooms`}>
          <RoomsRoute area={item} />
        </Route>
        <Route path={`${path}/types`}>
          <RoomtypesRoute area={item} />
        </Route>
        <Route path={`${path}/invoices`}>
          <InvoicesHistory area={item} />
        </Route>
        <Route path={`${path}/terminates`}>
          <Terminates area={item} />
        </Route>
        <Route path={`${path}/accounts`}>
          <Accounts area={item} />
        </Route>
        <Route path={`${path}/tutorials`}>
          <TutorialHistory area={item} />
        </Route>
      </Switch>
    </Flex.Item>
  );

  return (
    <Screen>
      <Session />
      <Menu />
      <Flex.Item>
        <Flex direction={Flex.V}>
          <Header />
          {(item && account) && render_sales()}
        </Flex>
      </Flex.Item>
    </Screen>
  );
};

export { Guests };
