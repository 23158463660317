import { BASE_PATH } from 'consts';
import { common } from '../../../services/globals';

const PATH_V1 = `${BASE_PATH.GUESTS}/v1/rooms`;
const PATH_V2 = `${BASE_PATH.GUESTS}/v2/rooms`;

const get_by_id = common.get_by_id(PATH_V1);
const find_original = common.find(PATH_V1);
const find = common.find(PATH_V2);
const find_visitors = common.find(`${PATH_V2}/reports`);
const find_current = common.find(`${PATH_V2}/current`);
const find_by = common.find_by(PATH_V1);
const create = common.create(PATH_V1);
const update = common.update(PATH_V1);
const remove = common.remove(PATH_V1);

const create_room_state = (_id, params) => (
  common.get_session().post(`${PATH_V2}/${_id}/room-states`, params)
);

const update_room_state = (_id, params) => (
  common.get_session().put(`${PATH_V2}/${_id}/room-states`, params)
);

export default {
  get_by_id,
  find,
  find_original,
  find_visitors,
  find_current,
  find_by,
  create,
  remove,
  update,
  create_room_state,
  update_room_state,
};
