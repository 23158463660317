import { GENERIC } from 'consts';

export const CONTEXT = 'guests';
export const TITLE = 'tipos de habitaciones';
export const ITEM_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'area de creacion y edicion de typos habitacion',
};
export const ITEMS_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'lista de tipos habitacion',
};

export const ADD_NEW_ITEM = 'Crear nuevo tipo';
export const CREATE_NEW_ITEM = 'Crear tipo';
export const DELETE_NEW_ITEM = 'Eliminar tipo';
export const UPDATE_NEW_ITEM = 'Actualizar tipo';

export const BASE_URL = `${GENERIC.APP_BASE_URL}/types`;
