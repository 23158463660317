import React, { useEffect, useState } from 'react';
// import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { useRouteMatch, useParams } from 'react-router-dom';
// import $ from 'jquery';

import { Screen1 } from 'components/screens/v1';
import { Flex } from 'components/grids/v4';
import global_api from 'generic/api/globals/v1';
import api from 'generic/api/guests/v1';
import { toast } from 'generic/services/globals';

import { Header } from './Header';
// import { Hotel } from './Hotel';

const H = () => {
  // function main() {
  //   (function() {
  //     $('a.page-scroll').click(function() {
  //         if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
  //       && location.hostname == this.hostname) {
  //           var target = $(this.hash);
  //           target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
  //           if (target.length) {
  //             $('html,body').animate({
  //               scrollTop: target.offset().top - 40
  //             }, 900);
  //             return false;
  //           }
  //         }
  //       });

  //     // Show Menu on Book
  //     $(window).bind('scroll', function() {
  //         var navHeight = $(window).height() - 500;
  //         if ($(window).scrollTop() > navHeight) {
  //             $('.navbar-default').addClass('on');
  //         } else {
  //             $('.navbar-default').removeClass('on');
  //         }
  //     });

  //     // $('body').scrollspy({
  //     //     target: '.navbar-default',
  //     //     offset: 80
  //     // });

  //   // Hide nav on click
  //   $(".navbar-nav li a").click(function (event) {
  //     // check if window is small enough so dropdown is created
  //     var toggle = $(".navbar-toggle").is(":visible");
  //     if (toggle) {
  //       $(".navbar-collapse").collapse('hide');
  //     }
  //   });

  //     // // Portfolio isotope filter
  //     // $(window).load(function() {
  //     //     var $container = $('.portfolio-items');
  //     //     $container.isotope({
  //     //         filter: '*',
  //     //         animationOptions: {
  //     //             duration: 750,
  //     //             easing: 'linear',
  //     //             queue: false
  //     //         }
  //     //     });
  //     //     $('.cat a').click(function() {
  //     //         $('.cat .active').removeClass('active');
  //     //         $(this).addClass('active');
  //     //         var selector = $(this).attr('data-filter');
  //     //         $container.isotope({
  //     //             filter: selector,
  //     //             animationOptions: {
  //     //                 duration: 750,
  //     //                 easing: 'linear',
  //     //                 queue: false
  //     //             }
  //     //         });
  //     //         return false;
  //     //     });
  //     // });

  //     // // Nivo Lightbox
  //     // $('.portfolio-item a').nivoLightbox({
  //     //         effect: 'slideDown',
  //     //         keyboardNav: true,
  //     //     });
  //   }());
  // }
  // main();

  const { path } = useRouteMatch();
  const { code } = useParams();

  const [area, set_area] = useState(null);
  const [types, set_types] = useState([]);
  /* eslint-disable no-console */
  console.log(path);
  /* eslint-disable no-console */
  console.log(types);

  useEffect(() => {
    global_api.areas.get_by_code(code)
    .then(({ data }) => {
      set_area(data);
      api.room_types.get_room_types(data._id)
      .then(({ data: room_types }) => {
        set_types([...room_types]);
      })
      .catch(toast.api_danger);
    })
    .catch(toast.api_danger);
  }, [code]);

  return (
    <Screen1>
      <Flex.Item>
        <Flex direction={Flex.V}>
          <Flex.Item>
            {area && <Header area={area} />}
            <div>
              {/* <Switch>
                <Route exact path={path}>
                  {area && <Hotel area={area} types={types} />}
                </Route>
              </Switch> */}
            </div>
          </Flex.Item>
        </Flex>
      </Flex.Item>
    </Screen1>
  );
};

export { H };
