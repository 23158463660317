import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'components/grids/v2';
import { ExtraListItem } from './ExtraListItem';

import './ExtraList.scss';

const ExtraList = ({ items, on_update, on_delete }) => {
  const render_item = (data, index) => (
    <ExtraListItem
      key={index}
      index={index}
      data={data}
      on_update={on_update}
      on_delete={on_delete}
    />
  );

  return (
    <Flex.Item>
      <div className="linear-list">
        {items.map(render_item)}
      </div>
    </Flex.Item>
  );
};

ExtraList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  on_update: PropTypes.func,
  on_delete: PropTypes.func,
};

ExtraList.defaultProps = {
  on_update: null,
  on_delete: null,
};

export { ExtraList };
