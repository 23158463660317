import React from 'react';
import PropTypes from 'prop-types';

import { ListItem } from './ListItem';

import './List.scss';

const List = ({ items, on_select }) => {
  const render_item = (item) => (
    <ListItem
      key={item._id}
      item={item}
      on_select={on_select}
    />
  );

  return (
    <div className="guests-main-card-list">
      <div>
        {items.map(render_item)}
      </div>
    </div>
  );
};

List.propTypes = {
  items: PropTypes.any.isRequired,
  on_select: PropTypes.func.isRequired,
};

export { List };
