import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import global_api from 'generic/api/globals/v1';
import global_actions from 'actions/globals/v1';
import { toast, StorageService } from 'generic/services/globals';

const Account = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = StorageService.load('token');
    if (!token) {
      dispatch(global_actions.accounts.load({ loading: true, account: null }));
      return;
    }

    global_api.accounts.find_current({})
    .then(({ data }) => {
      dispatch(global_actions.accounts.load({ loading: true, account: data }));
    })
    .catch(toast.api_danger);
    // .catch(() => {
    //   dispatch(global_actions.accounts.load({ loading: true, account: null }));
    // });
  }, [dispatch]);

  return null;
};

export { Account };
