const enviroment = process.env.NODE_ENV || 'production';

const BASE_PATH = {
  development: {
    ACCOUNTS: 'http://192.168.56.102:10101',
    FILES: 'http://192.168.56.102:10201',
    SOCKET: 'http://192.168.56.102:10401',

    MONITOR: 'http://192.168.56.102:10013',
    SALES: 'http://192.168.56.102:10012',
    GUESTS: 'http://192.168.56.102:10016',
  },
  production: {
    ACCOUNTS: 'https://accounts.unboliviano.com',
    FILES: 'https://files.unboliviano.com',
    SOCKET: 'https://socket.unboliviano.com',

    MONITOR: 'https://monitor.unboliviano.com',
    SALES: 'https://sales.unboliviano.com',
    GUESTS: 'https://guests.unboliviano.com',
  },
}[enviroment];

export { BASE_PATH };
