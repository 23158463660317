import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import api from 'generic/api/globals/v1';
import { GENERIC } from 'consts';
import { toast } from 'generic/services/globals';
import { ContentTitle } from 'components';
import { IButton } from 'components/buttons/v2';
import { useHistory } from 'react-router-dom';
import { Flex } from 'components/grids/v2';
import { LinearList } from 'components/lists/v1';
import { AREA_GROUPS_TITLE } from '../groups.constants';

const AreaGroups = ({ area }) => {
  const history = useHistory();
  const [groups, set_groups] = useState([]);

  const on_create = useCallback(() => {
    api.groups.create({
      name: 'Group Inc.',
      description: 'description generator',
      image_id: null,
      enabled: true,
    })
    .then(({ data }) => {
      set_groups([data, ...groups]);
    })
    .catch(toast.api_danger);
  }, [groups]);

  const on_select = useCallback((group) => {
    history.push(`${GENERIC.APP_BASE_URL}/configuration/groups/${group._id}`);
  }, [history]);

  useEffect(() => {
    /* eslint-disable no-console */
    console.log(area);

    api.groups.find({})
    .then(({ data }) => {
      set_groups(data);
    })
    .catch(toast.api_danger);
  }, [area]);

  return (
    <Flex direction={Flex.V}>
      <ContentTitle config={AREA_GROUPS_TITLE} />
      <Flex.Item>
        <Flex.End>
          <IButton label="Crear nuevo grupo" icon="plus" on_click={on_create} />
        </Flex.End>
        <LinearList items={groups} on_select={on_select} />
      </Flex.Item>
    </Flex>
  );
};

AreaGroups.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { AreaGroups };
