import React from 'react';
import PropTypes from 'prop-types';

import './RoomMaintenance.scss';
import { RoomState } from './RoomState';

const TYPE = {
  housekeeping: 'housekeeping',
  maintenance: 'maintenance',
};

const RoomMaintenance = ({ area, room, set_room }) => {
  const housekeeping_item = {
    description: `La habitacion #${room.name} nesecita limpieza`,
    type: TYPE.housekeeping,
  };
  const maintenance_item = {
    description: `La habitacion #${room.name} nesecita mantenimiento por unos dias`,
    type: TYPE.maintenance,
  };
  const housekeeping_config = {
    _id: 'housekeeping_id',
    initial_label: 'Solicitar limpieza',
    complete_label: 'Completar limpieza',
  };
  const maintenance_config = {
    _id: 'maintenance_id',
    initial_label: 'Habitación en mantenimiento',
    complete_label: 'Terminar mantenimiento',
  };

  return (
    <>
      <RoomState
        area={area}
        room={room}
        set_room={set_room}
        item={housekeeping_item}
        configuration={housekeeping_config}
      />
      <RoomState
        area={area}
        room={room}
        set_room={set_room}
        item={maintenance_item}
        configuration={maintenance_config}
      />
    </>
  );
};

RoomMaintenance.propTypes = {
  area: PropTypes.any.isRequired,
  room: PropTypes.any.isRequired,
  set_room: PropTypes.func.isRequired,
};

export { RoomMaintenance };
