import moment from 'moment';

const calc_days = (booking) => {
  const start = moment(booking.start);
  const end = moment(booking.end);
  return end.diff(start, 'days') + 1;
};

const render_customer = (customer) => (
  customer.is_person ? `${customer.first_name} ${customer.last_name}` : customer.company_name
);

const visitor_names = (visitors) => {
  const names = visitors.map((item) => render_customer(item));
  return names.length > 0 ? `de ${names.join(', ')}` : '';
};

const populate_rooms = (ids, rooms) => (ids.map((_id) => rooms.find((room) => room._id === _id)));

const calc_products = (ids, otros, amount, rooms, room_types) => {
  const current_rooms = populate_rooms(ids, rooms);
  return current_rooms.map((room) => {
    const room_type = room_types.find((type) => type._id === room.room_type_id);
    const price = otros[room._id];
    return {
      room_id: room._id,
      room,
      room_type,
      amount,
      type: 'room',
      name: `Hospedaje ${room.name} (${room_type.abbreviation})`,
      original: room_type.price,
      price: price || room_type.price,
    };
  });
};

const calc_products_v2 = (ids, otros, amount, rooms, room_types, visitors) => {
  const current_rooms = populate_rooms(ids, rooms);
  return current_rooms.map((room) => {
    const room_type = room_types.find((type) => type._id === room.room_type_id);
    const room_visitor = visitors.filter((item) => item.room_id === room._id)
    .map((item) => item.customer);
    const price = otros[room._id];
    return {
      room_id: room._id,
      room,
      room_type,
      amount,
      type: 'room',
      name: `Hospedaje ${visitor_names(room_visitor)} (hab. ${room.name} ${room_type.abbreviation})`,
      original: room_type.price,
      price: price || room_type.price,
    };
  });
};

export { calc_days, calc_products, calc_products_v2 };
