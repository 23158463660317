import React from 'react';
import PropTypes from 'prop-types';

import { ProductListItem } from './ProductListItem';

import './ProductList.scss';

const ProductList = ({ items, amount, on_update }) => {
  const render_item = (data, index) => (
    <ProductListItem
      key={index}
      data={data}
      amount={amount}
      on_update={on_update}
    />
  );

  return (
    <div className="main-product-list">
      <div className="linear-list">
        <div className="name">Habitacion</div>
        <div className="data">Dias</div>
        <div className="data">Precio</div>
        <div className="field-title">Precio Actual</div>
        <div className="data">Total</div>
      </div>
      {items.map(render_item)}
    </div>
  );
};

ProductList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  amount: PropTypes.number.isRequired,
  on_update: PropTypes.func,
};

ProductList.defaultProps = {
  on_update: null,
};

export { ProductList };
