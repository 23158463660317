import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { IButton } from 'components/buttons/v2';
import { useFormReducer } from 'generic/functions/globals/reducer';

import './ExtraListItem.scss';

const ExtraListItem = ({ index, data, on_update, on_delete }) => {
  const {
    item,
    set_item,
  } = useFormReducer(data);

  const on_submit = (e) => {
    e.preventDefault();
  };

  const on_local_input_change = useCallback((event) => {
    const current = { ...item, [event.target.name]: event.target.value };
    set_item(current);
    on_update(index, current);
  }, [index, item, set_item, on_update]);

  const on_local_number_change = useCallback((event) => {
    const current = { ...item, [event.target.name]: parseInt(event.target.value, 10) };
    set_item(current);
    on_update(index, current);
  }, [index, item, set_item, on_update]);

  const on_select_delete = useCallback(() => {
    on_delete(index, item);
  }, [index, item, on_delete]);

  return (
    <div>
      <Form onSubmit={on_submit} inverted>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Servicio</label>
            <input name="name" placeholder="Description" value={item.name} onChange={on_local_input_change} />
          </Form.Field>
          <Form.Field>
            <label>Precio</label>
            <input name="price" type="number" placeholder="Precio" value={item.price} onChange={on_local_number_change} />
          </Form.Field>
          <Form.Field>
            <IButton label="Quitar" icon="delete" on_click={() => on_select_delete(item)} />
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
};

ExtraListItem.propTypes = {
  on_update: PropTypes.func,
  on_delete: PropTypes.func,
  data: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
};

ExtraListItem.defaultProps = {
  on_update: () => {},
  on_delete: () => {},
};

export { ExtraListItem };
