import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import api from 'generic/api/guests/v1';
import { LinearListOption } from 'components/lists/v1';
import { toast } from 'generic/services/globals';
import { IButton } from 'components/buttons/v2';
import { Flex } from 'components/grids/v2';
import { VisitorSearchModal } from '../client/VisitorSearchModal';

import './RoomVisitors.scss';

const RoomVisitors = ({ booking, set_booking }) => {
  const { room_id } = useParams();
  const [open, set_open] = useState(false);

  const on_select = useCallback(() => {
  }, []);

  const on_select_delete = useCallback((item) => {
    const filtered = booking.visitors.filter((o) => o.customer_id !== item._id);
    set_booking({ ...booking, visitors: filtered });
    api.bookings.update({ _id: booking._id, visitors: filtered })
    .catch(toast.api_danger);
  }, [booking, set_booking]);

  const set_customer = useCallback((customer) => {
    booking.visitors.push({
      room_id,
      customer_id: customer._id,
      customer: {
        _id: customer._id,
        is_person: customer.is_person,
        customer_index: customer.customer_index,
        customer_name: customer.customer_name,
        first_name: customer.first_name,
        last_name: customer.last_name,
        company_name: customer.company_name,
      },
    });
    set_booking({ ...booking, visitors: booking.visitors });
    api.bookings.update({ _id: booking._id, visitors: booking.visitors })
    .catch(toast.api_danger);
  }, [booking, set_booking, room_id]);

  const render_options = (item) => (
    <IButton label="Quitar" icon="delete" on_click={() => on_select_delete(item)} />
  );

  const render_visitors = (items) => {
    const current_visitors = items.filter((item) => item.room_id === room_id);

    return current_visitors.map((item) => item.customer).map((item) => ({
      _id: item._id,
      name: item.is_person ? `${item.first_name} ${item.last_name}` : item.company_name,
      description: item.customer_index,
    }));
  };

  return (
    <>
      <VisitorSearchModal
        open={open}
        set_open={set_open}
        set_customer={set_customer}
      />
      <Flex direction={Flex.V}>
        <Flex.Item>
          <Flex direction={Flex.V}>
            <Flex.Item>
              <h2>Visitantes</h2>
              <LinearListOption
                items={render_visitors(booking.visitors)}
                on_select={on_select}
                options={render_options}
              />
            </Flex.Item>
            <Flex.End>
              <IButton label="Registrar visitantes" icon="checkmark" on_click={() => set_open(true)} />
            </Flex.End>
          </Flex>
        </Flex.Item>
      </Flex>
    </>
  );
};

RoomVisitors.propTypes = {
  booking: PropTypes.any.isRequired,
  set_booking: PropTypes.func.isRequired,
};

export { RoomVisitors };
