import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import api from 'generic/api/globals/v1';
import { GENERIC } from 'consts';
import { toast } from 'generic/services/globals';
import actions from 'actions/globals/v1';
import { ContentTitle } from 'components';
import { useHistory } from 'react-router-dom';
import { Flex } from 'components/grids/v2';
import { IButton } from 'components/buttons/v2';
import { LinearListOption } from 'components/lists/v1';
import { LIST_AREAS_TITLE } from '../areas.constants';

const Areas = ({ area }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { areas } = useSelector((state) => state.areas);

  const on_create = useCallback(() => {
    api.areas.create({
      name: 'Group Area Inc.',
      description: 'description generator',
      image_id: null,
      enabled: true,
      group_id: area.group_id,
    })
    .then(({ data }) => {
      dispatch(actions.areas.set_one(data));
    })
    .catch(toast.api_danger);
  }, [dispatch, area]);

  const on_select = useCallback((item) => {
    history.push(`${GENERIC.APP_BASE_URL}/configuration/areas/${item._id}`);
  }, [history]);

  const on_select_area = useCallback((item) => {
    dispatch(actions.apps.set_area(item));
  }, [dispatch]);

  const render_options = (item) => (
    <IButton label="Seleccionar area" icon="checkmark" on_click={() => on_select_area(item)} />
  );

  useEffect(() => {
    api.areas.find({})
    .then(({ data }) => {
      dispatch(actions.areas.set(data));
    })
    .catch(toast.api_danger);
  }, [dispatch]);

  return (
    <Flex direction={Flex.V}>
      <ContentTitle config={LIST_AREAS_TITLE} />
      <Flex.Item>
        <Flex.End>
          <IButton label="Crear nueva area" icon="plus" on_click={on_create} />
        </Flex.End>
        <LinearListOption items={areas} on_select={on_select} options={render_options} />
      </Flex.Item>
    </Flex>
  );
};

Areas.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
    group_id: PropTypes.string,
  }).isRequired,
};

export { Areas };
