import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import api from 'generic/api/guests/v1';
import { useFormReducer } from 'generic/functions/globals/reducer';
import { toast } from 'generic/services/globals';
import { IButton, UImage, ContentTitle } from 'components';
import { AREA_UPDATE_TITLE } from '../setting.constants';

const SettingLogo = ({ area }) => {
  const {
    item,
    set_item,
    on_image_change,
  } = useFormReducer({});

  const handle_submit = (e) => {
    e.preventDefault();
    api.settings.update(area._id, item)
    .then(() => {
      toast.success('se actualizo correctamente');
    })
    .catch(toast.api_danger);
  };

  useEffect(() => {
    api.settings.get_by_id(area._id)
    .then(({ data }) => {
      set_item({
        image_id: data.image_id,
      });
    })
    .catch(toast.api_danger);
  }, [set_item, area]);

  return (
    <>
      <ContentTitle config={AREA_UPDATE_TITLE} />
      <Form onSubmit={handle_submit} inverted>
        <UImage image={item.image_id} height="150px" quality={1080} on_callback={on_image_change} />
        <IButton label="Actualizar información" icon="checkmark" submit />
      </Form>
    </>
  );
};

SettingLogo.propTypes = {
  area: PropTypes.any.isRequired,
};

export { SettingLogo };
