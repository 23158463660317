import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import moment_duration from 'moment-duration-format';

import actions from 'actions/guests';
import api from 'generic/api/guests/v1';
import { toast } from 'generic/services/globals';
import { Flex } from 'components/grids/v4';

import './ListItem.scss';
import { calc_days } from '../room_builder';
import 'moment/locale/es';

moment.locale('es');
moment_duration(moment);

const ListItem = ({ item, on_select }) => {
  const dispatch = useDispatch();
  const { booking: current_booking, rooms, room_types } = useSelector((state) => state.main);

  const [room] = useState(item);
  const [booking, set_booking] = useState(item.booking);
  const [type, set_type] = useState({});
  const [booking_room, set_booking_room] = useState(null);

  const on_click = (e) => {
    e.stopPropagation();
    on_select(item);
  };

  const on_select_booking = (e) => {
    e.stopPropagation();
    if (booking) {
      dispatch(actions.main.set_booking(booking));
      return;
    }
    if (!current_booking) {
      // eslint-disable-next-line
      console.error('booking was not select');
      return;
    }

    api.bookings.update({
      _id: current_booking._id,
      room_ids: [...current_booking.room_ids, room._id],
    })
    .then(({ data }) => {
      set_booking(data);
      dispatch(actions.main.set_booking(data));
    })
    .catch(toast.api_danger);
  };

  useEffect(() => {
    if (!booking) return;

    const current_room = rooms.find((o) => o._id === booking.room_id);
    if (current_room) {
      set_booking_room(current_room);
    }
  }, [booking, rooms]);

  useEffect(() => {
    if (!room.room_type_id) return;

    const current_type = room_types.find((o) => o._id === room.room_type_id);
    set_type(current_type);
  }, [room_types, room]);

  const render_housekeeping = () => {
    if (!room.housekeeping_id) {
      return null;
    }

    return (
      <div className="housekeeping" />
    );
  };

  const render_maintenance = () => {
    if (!room.maintenance_id) {
      return null;
    }

    return (
      <div className="maintenance" />
    );
  };

  const render_sale = () => {
    if (!room.sale) {
      return null;
    }

    return (
      <div className="sale" />
    );
  };

  const render_sale_customer = () => {
    if (!room.sale || !room.sale.customer) {
      return null;
    }

    return (
      <div>
        <div>{room.sale.customer.customer_index}</div>
        <div>{room.sale.customer.customer_name}</div>
      </div>
    );
  };

  const render_booking_room = () => {
    if (!booking_room) return null;

    if (current_booking && current_booking.room_id === booking_room._id) {
      return (
        <div className="booking">
          <h1>{`#${booking_room.name}`}</h1>
        </div>
      );
    }

    return (
      <div className="booking">
        <h1>{booking_room.name}</h1>
      </div>
    );
  };

  const render_visitor = (visitor, index) => (
    <div key={visitor._id}>{` ${index + 1}.- ${visitor.first_name} ${visitor.last_name}`}</div>
  );

  const render_card_content = () => {
    if (!booking) return <div className="attribute">{room.attribute}</div>;

    if (booking.visitors.length === 0) {
      return (
        <div className="attribute">
          <div><i>NOTA:</i></div>
          <div><i>1.- Registrar los visitantes</i></div>
          <div><i>2.- Completar el pago</i></div>
        </div>
      );
    }

    return (
      <div className="attribute">
        {booking.visitors.map((visitor, index) => (render_visitor(visitor, index)))}
      </div>
    );
  };

  const render_header = () => {
    if (!type) return null;

    return (
      <div className="header" style={{ background: type.color }}>
        <div>{`${type.name} (${type.price}Bs.)`}</div>
      </div>
    );
  };

  const calc_date_duration = (data) => {
    const start = moment(data.created);
    const end = moment();
    const duration = end.diff(start);
    const duration_string = moment.duration(duration, 'milliseconds')
    .format(duration < 3600000 ? 'm[m] s[s]' : 'D[d] H[h]');
    return `${calc_days(data)}d-${duration_string}`;
  };

  const render_availability = () => {
    const message = booking ? calc_date_duration(booking) : 'disponible';

    return (
      <div className="footer" onClick={on_select_booking}>
        <h2 className="availability">{message}</h2>
      </div>
    );
  };

  return (
    <div className={classNames('guests-main-list-item', booking ? 'room-busy' : 'room-free')} onClick={on_click}>
      {render_header()}
      {render_maintenance()}
      {render_housekeeping()}
      {render_sale()}
      <div className="content">
        <div>
          <Flex direction={Flex.H}>
            <div className="name">
              {`${room.name}`}
            </div>
            <Flex.Item>
              {render_sale_customer()}
            </Flex.Item>
          </Flex>
        </div>
        {render_card_content()}
      </div>
      {render_booking_room()}
      {render_availability()}
    </div>
  );
};

ListItem.propTypes = {
  item: PropTypes.any.isRequired,
  on_select: PropTypes.func.isRequired,
};

export { ListItem };
