import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import api from 'generic/api/guests/v1';
import { toast } from 'generic/services/globals';
import { Flex } from 'components/grids/v2';
import { UImage, UImages } from 'components/images/v2';
import { useFormReducer } from 'generic/functions/globals/reducer';
import { RemoveConfirmation } from 'components/generics/v1';
import { ContentTitle } from 'components';
import { IButton } from 'components/buttons/v2';
import { ITEM_HEADER, CREATE_NEW_ITEM, DELETE_NEW_ITEM, UPDATE_NEW_ITEM, BASE_URL } from './constants';

const RoomTypeEdit = ({ area }) => {
  const { _id } = useParams();
  const history = useHistory();
  const {
    item,
    set_item,
    on_string_change,
    on_uimage_change,
    on_images_change,
    on_number_change,
  } = useFormReducer({
    area_id: area._id,
    image_id: null,
    image_ids: [],
    color: '#ff006d',
  });
  const [is_new] = useState(_id === undefined);

  const on_submit = (e) => {
    e.preventDefault();
    if (is_new) {
      api.room_types.create(item)
      .then(() => {
        history.push(BASE_URL);
      })
      .catch(toast.api_danger);
    } else {
      api.room_types.update(item)
      .then(() => {
        history.push(BASE_URL);
      })
      .catch(toast.api_danger);
    }
  };

  const on_remove_callback = useCallback(() => {
    api.room_types.remove(item)
    .then(() => {
      history.push(BASE_URL);
    })
    .catch(toast.api_danger);
  }, [history, item]);

  useEffect(() => {
    if (is_new) return;

    api.room_types.get_by_id(_id)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [is_new, _id, set_item]);

  return (
    <>
      <ContentTitle config={ITEM_HEADER} />
      <Form onSubmit={on_submit} inverted>
        <Form.Field>
          <label>Nombre</label>
          <input name="name" placeholder="Suite, Doble, Individual" value={item.name} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Abreviatura</label>
          <input name="abbreviation" placeholder="I, D, S, etc" value={item.abbreviation} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Precio</label>
          <input name="price" type="number" placeholder="110, 200, etc" value={item.price} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Precio</label>
          <input name="color" type="color" placeholder="110, 200, etc" value={item.color} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Descripción</label>
          <textarea name="description" placeholder="Descripción" value={item.description} onChange={on_string_change} />
        </Form.Field>
        <UImage image={item.image_id} height="150px" quality={1080} on_callback={on_uimage_change} />
        <UImages ids={item.image_ids} width="100%" height="100px" quality={1080} on_callback={on_images_change} />
        <Flex.End>
          {!is_new && <RemoveConfirmation label={DELETE_NEW_ITEM} on_click={on_remove_callback} />}
          {is_new && <IButton label={CREATE_NEW_ITEM} icon="checkmark" submit />}
          {!is_new && <IButton label={UPDATE_NEW_ITEM} icon="checkmark" submit />}
        </Flex.End>
      </Form>
    </>
  );
};

RoomTypeEdit.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { RoomTypeEdit };
