import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import api from 'generic/api/guests/v1';
import { toast } from 'generic/services/globals';

import './RoomDescription.scss';

const message = [
  'Adelanto: 0',
  'Control TV: si',
  'LLavero: si',
  '',
].join(' \n');

const RoomDescription = ({ booking }) => {
  const [item, set_item] = useState({
    _id: booking._id,
    description: booking.description || message,
  });

  const on_string_change = (event) => {
    set_item({ ...item, [event.target.name]: event.target.value });
  };

  const save_booking = useCallback(() => {
    api.bookings.update(item)
    .catch(toast.api_danger);
  }, [item]);

  useEffect(() => {
    const timeout_id = setTimeout(() => save_booking(), 500);
    return () => clearTimeout(timeout_id);
  }, [save_booking, item]);

  return (
    <div>
      <h2>Notas</h2>
      <Form inverted>
        <Form.Field>
          <label>Descripción</label>
          <textarea
            name="description"
            placeholder="Descripción"
            rows="4"
            value={item.description}
            onChange={on_string_change}
          />
        </Form.Field>
      </Form>
    </div>
  );
};

RoomDescription.propTypes = {
  booking: PropTypes.any.isRequired,
};

export { RoomDescription };
