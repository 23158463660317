import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { FormContainer, Flex } from 'components/grids/v2';
import { RoomType } from './RoomType';
import { RoomTypes } from './RoomTypes';
import { RoomTypeEdit } from './RoomTypeEdit';

const RoomtypesRoute = ({ area }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Flex.Center>
          <FormContainer>
            <RoomTypes area={area} />
          </FormContainer>
        </Flex.Center>
      </Route>
      <Route exact path={`${path}/new`}>
        <Flex.Center>
          <FormContainer>
            <RoomTypeEdit area={area} />
          </FormContainer>
        </Flex.Center>
      </Route>
      <Route exact path={`${path}/:_id/edit`}>
        <Flex.Center>
          <FormContainer>
            <RoomTypeEdit area={area} />
          </FormContainer>
        </Flex.Center>
      </Route>
      <Route exact path={`${path}/:_id`}>
        <Flex.Center>
          <FormContainer>
            <RoomType area={area} />
          </FormContainer>
        </Flex.Center>
      </Route>
    </Switch>
  );
};

RoomtypesRoute.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { RoomtypesRoute };
