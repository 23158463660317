import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import api from 'generic/api/guests/v1';
import { toast } from 'generic/services/globals';
import { FormLabels } from 'components/forms/v1';
import { ModelTitle } from 'components/titles/v2';
import { IButton } from 'components/buttons/v2';
import { Flex } from 'components/grids/v2';
import { CONTEXT, BASE_URL, UPDATE_NEW_ITEM } from './constants';

const RoomType = () => {
  const { _id } = useParams();
  const history = useHistory();
  const [item, set_item] = useState({
    accounts: [],
  });

  const on_update = () => {
    history.push(`${BASE_URL}/${item._id}/edit`);
  };

  useEffect(() => {
    api.room_types.get_by_id(_id)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [_id, set_item]);

  return (
    <FormLabels>
      <ModelTitle context={CONTEXT} title={item.name} description={item.description} />
      <Flex.End>
        <IButton label={UPDATE_NEW_ITEM} icon="edit" on_click={on_update} />
      </Flex.End>
    </FormLabels>
  );
};

RoomType.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { RoomType };
