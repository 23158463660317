import React from 'react';

import { Screen } from 'components/screens/v1';

const Explore = () => (
  <Screen>
    Explorar
  </Screen>
);

export { Explore };
