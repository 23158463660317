import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { CheckoutInvoiceCart } from './CheckoutInvoiceCart';

const InvoicesReceipt = ({ sale }) => {
  const { setting } = useSelector((state) => state.area);

  const render_custoner = (customer) => {
    if (!customer) {
      return null;
    }

    return (
      <>
        <div>
          {`NIT/CI: ${sale.customer.customer_index}`}
        </div>
        <div>
          {`NOMBRE: ${sale.customer.customer_name}`}
        </div>
      </>
    );
  };

  return (
    <div id="checkout-invoice" style={{ width: '300px' }}>
      <div style={{ background: '#ffffff', overflowY: 'auto', color: '#000000', padding: '10px' }}>
        <div style={{ textAlign: 'center', fontSize: '16px' }}>
          <h3 style={{ margin: '0px' }}>
            {setting.area_name}
          </h3>
        </div>
        <div style={{ textAlign: 'center', fontSize: '12px', marginBottom: '10px' }}>
          <div>
            {setting.area_address}
          </div>
          <div>
            {setting.area_phone}
          </div>
          <div>
            {setting.area_city}
          </div>
        </div>
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <h5 style={{ margin: '0px' }}>
            RECIBO
          </h5>
          <div>
            {`NIT: ${setting.area_nit}`}
          </div>
          <div>
            {`Actividad Económica: ${setting.area_activity}`}
          </div>
        </div>
        <div>
          <div>
            {`FECHA: ${moment(sale.created).format('DD/MM/YYYY h:mm A')}`}
          </div>
          {render_custoner(sale.customer)}
        </div>
        <CheckoutInvoiceCart sale={sale} />
        <div>
          <div>
            {`Emitido por: ${sale.account.last_name}`}
          </div>
          <div style={{ textAlign: 'center' }}>
            Gracias por su preferencia...
          </div>
          <div style={{ textAlign: 'center', fontSize: '8px' }}>
            hotel.unboliviano.com
          </div>
        </div>
      </div>
    </div>
  );
};

InvoicesReceipt.propTypes = {
  sale: PropTypes.any.isRequired,
};

export { InvoicesReceipt };
