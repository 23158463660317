import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import api from 'generic/api/guests/v1';
import { Header, EmptyInfo } from 'components/titles/v2';
import { toast } from 'generic/services/globals';
import { IButton } from 'components/buttons/v2';
import { Flex, Container } from 'components/grids/v3';

import { List } from './list/List';
import { generate_file } from '../utils/excel';

const PeopleHistory = ({ area }) => {
  const [items, set_items] = useState([]);

  const render_customer = (customer) => (
    customer.is_person ? `${customer.first_name} ${customer.last_name}` : customer.company_name
  );

  const parse_data = () => (
    items.map((item) => ({
      fecha: item.booking.start.format('MM/DD/YYYY'),
      salida: item.booking.end.format('MM/DD/YYYY'),
      tipo: item.room_type.name,
      habitacion: item.room.name,
      ci: item.customer.customer_index,
      nombre: render_customer(item.customer),
    }))
  );

  const on_download = () => {
    generate_file(parse_data(), 'people', 'visitors');
  };

  const load_visitors = useCallback(() => {
    api.rooms.find_current({ area_id: area._id })
    .then(({ data }) => {
      const visitors = data.map((o) => {
        const aux = { ...o };
        aux.booking.start = moment(o.booking.start);
        aux.booking.end = moment(o.booking.end);
        return o;
      });

      set_items(visitors);
    })
    .catch(toast.api_danger);
  }, [area]);

  useEffect(() => {
    load_visitors();
  }, [area, load_visitors]);

  return (
    <Flex direction={Flex.V}>
      <Container width="1200px">
        <Header title="Lista de visitantes en el hotel" />
        <Flex.End>
          <IButton label="Actualizar" icon="clock outline" on_click={load_visitors} />
          <IButton label="Descargar" icon="clock outline" on_click={on_download} />
        </Flex.End>
        <Flex.Item>
          {(items.length === 0) && <EmptyInfo description="No existe visitantes en el hotel" />}
          {(items.length > 0) && <List items={items} on_select={() => {}} />}
        </Flex.Item>
      </Container>
    </Flex>
  );
};

PeopleHistory.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { PeopleHistory };
