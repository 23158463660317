import React, { useCallback, useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';

import { GENERIC } from 'consts';
import { loader } from 'generic/services/globals/v2';
import api from 'generic/api/guests/v1';
import { toast, StorageService } from 'generic/services/globals';
import { ModelTitle } from 'components/titles/v2';
import { Flex } from 'components/grids/v2';
import { CONTEXT } from './constants';
import { ClientSearchModal } from './client/ClientSearchModal';
import { RoomProducts } from './helper/RoomProducts';
import { RoomVisitors } from './helper/RoomVisitors';
import { RoomExtrasProducts } from './helper/RoomExtrasProducts';
import { IRoomButton } from './helper/IRoomButton';
import { RoomMaintenance } from './room/RoomMaintenance';
import { RoomDescription } from './room/RoomDescription';
import { RoomToolbar } from './room/RoomToolbar';
import { RoomRetains } from './room/RoomRetains';

import './Room.scss';

const Room = ({ area }) => {
  const history = useHistory();
  const { room_id } = useParams();
  const { account } = useSelector((state) => state.auth);
  const { rooms } = useSelector((state) => state.main);

  const [open, set_open] = useState(false);
  const [sale, set_sale] = useState(null);
  const [item, set_item] = useState(null);
  const [room, set_room] = useState(null);
  const [dates, set_dates] = useState(null);

  const on_change_dates = useCallback(({ range1 }) => {
    const booking = {
      _id: item._id,
      start: moment(range1.startDate).startOf('day'),
      end: moment(range1.endDate).endOf('day'),
    };

    set_dates({
      startDate: booking.start.toDate(),
      endDate: booking.end.toDate(),
    });

    api.bookings.update(booking)
    .then(({ data }) => {
      set_item({ ...item, start: moment(data.start), end: moment(data.end) });
    })
    .catch(toast.api_danger);
  }, [item]);

  const create_booking = useCallback(() => {
    const new_item = {
      start: moment().startOf('day'),
      end: moment().endOf('day'),
      area_id: area._id,
      room_id: room._id,
      customer: null,
      room_ids: [room._id],
      visitors: [],
      account_id: account._id,
      account,
      otros: {},
      rooms: [],
      products: [],
      retains: [],
      active: true,
      completed: null,

      coin_type: 'cash',
      payment_type: 'receipt',
      order_type: null,
      index: 0,
    };

    const config = StorageService.load('checkout-config');
    if (config) {
      new_item.coin_type = config.coin_type.key;
      new_item.payment_type = config.payment_type.key;
      new_item.order_type = config.order_type.key;
    }

    loader.resolve()
    .then(loader.start)
    .then(() => api.bookings.create(new_item))
    .then(({ data }) => {
      const aux = { ...data };
      aux.start = moment(aux.start);
      aux.end = moment(aux.end);
      set_item(aux);
      set_dates({
        startDate: aux.start.toDate(),
        endDate: aux.end.toDate(),
      });
    })
    .then(loader.stop)
    .catch(loader.error)
    .catch(toast.api_danger);
  }, [account, room, area]);

  const set_customer = useCallback((customer) => {
    set_item({ ...item, customer });
    api.bookings.update({ _id: item._id, customer })
    .catch(toast.api_danger);
  }, [item]);

  const complete_booking = useCallback(() => {
    loader.resolve()
    .then(loader.start)
    .then(() => api.bookings.checkout(item._id))
    .then(() => {
      set_item(null);
      set_sale(null);
    })
    .then(loader.stop)
    .catch(loader.error)
    .catch(toast.api_danger);
  }, [item]);

  const on_pay = useCallback(() => {
    history.push(`${GENERIC.APP_BASE_URL}/main/booking/${item._id}/checkout`);
  }, [history, item]);

  const on_take_away = useCallback(() => {
    api.bookings.update({ _id: item._id, room_ids: item.room_ids.filter((o) => o !== room._id) })
    .then(() => {
      history.push(`${GENERIC.APP_BASE_URL}/main`);
    })
    .catch(toast.api_danger);
  }, [history, item, room]);

  useEffect(() => {
    if (!item) {
      return;
    }

    api.sales.find({ _id: item._id })
    .then(({ data }) => {
      const current = data[0];
      if (current && !current.active) {
        set_sale({
          _id: current._id,
        });
      }
    })
    .catch(toast.api_danger);
  }, [item]);

  useEffect(() => {
    loader.resolve()
    .then(loader.start)
    .then(() => api.rooms.get_by_id(room_id))
    .then(({ data }) => {
      set_room(data);
      return api.bookings.find({ room_ids: room_id, active: true });
    })
    .then(({ data }) => {
      const current = data[0];
      if (current) {
        current.start = moment(current.start);
        current.end = moment(current.end);
        set_item(current);
        set_dates({
          startDate: current.start.toDate(),
          endDate: current.end.toDate(),
        });
      }
    })
    .then(loader.stop)
    .catch(loader.error)
    .catch(toast.api_danger);
  }, [area, room_id]);

  const render_calendar = () => {
    if (!dates) return null;

    return (
      <div className="guests-main-room">
        <DateRangePicker
          ranges={[dates]}
          onChange={on_change_dates}
        />
      </div>
    );
  };

  const render_pay_action = () => {
    if (!item) {
      return null;
    }

    if (sale) {
      return (
        <IRoomButton label="Completado" icon="checkmark" color="green" on_click={on_pay} />
      );
    }
    return (
      <IRoomButton label="Pagar" icon="checkmark" color="warn" on_click={on_pay} />
    );
  };

  const render_take_away = () => {
    if (!item) return null;

    if (room_id === item.room_id) return null;

    return (
      <IRoomButton label="Quitar" icon="checkmark" color="gray" on_click={on_take_away} />
    );
  };

  const render_options = () => (
    <div className="guests-main-container">
      {room && <RoomMaintenance area={area} room={room} set_room={set_room} />}
      <IRoomButton label="CHECK IN" icon="checkmark" color="warn" on_click={create_booking} />
      <p style={{ textAlign: 'right' }}>(NOTA: usar solo un (1) click)</p>
    </div>
  );

  const render_booking = () => (
    <div className="guests-main-container">
      {room && <RoomMaintenance area={area} room={room} set_room={set_room} />}
      <IRoomButton label="CHECK OUT" icon="checkmark" color="gray" on_click={complete_booking} />
      <p style={{ textAlign: 'right' }}>(NOTA: usar solo un (1) click)</p>
      <ModelTitle context={CONTEXT} title={room.name} description={room.attribute} />
      <div>
        <RoomVisitors
          area={area}
          booking={item}
          set_booking={set_item}
        />
      </div>
      <div>
        <RoomProducts
          area={area}
          booking={item}
          set_booking={set_item}
        />
      </div>
      <RoomExtrasProducts area={area} booking={item} set_booking={set_item} />
      <RoomRetains area={area} booking={item} />
      <RoomDescription booking={item} />
      {render_calendar()}
      <div>
        {render_pay_action()}
        {render_take_away()}
      </div>
      <div>
        <ClientSearchModal
          open={open}
          set_open={set_open}
          customer={item.customer}
          set_customer={set_customer}
        />
      </div>
    </div>
  );

  return (
    <Flex direction={Flex.H}>
      <Flex.Item>
        <Flex direction={Flex.V}>
          <Flex.Scrap only={['computer', 'mobile']} class_name="guests-main-rooms-list">
            <RoomToolbar booking={item} rooms={rooms} />
          </Flex.Scrap>
          <Flex.Item>
            {!item && render_options()}
            {(item && dates) && render_booking()}
          </Flex.Item>
        </Flex>
      </Flex.Item>
      <Flex.Scrap only={['screen']} width="350px" class_name="guests-main-rooms-list">
        <RoomToolbar booking={item} rooms={rooms} />
      </Flex.Scrap>
    </Flex>
  );
};

Room.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { Room };
