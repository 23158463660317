import { BASE_PATH } from 'consts';
import { common } from '../../../services/globals';

const PATH_V1 = `${BASE_PATH.ACCOUNTS}/v1/accounts`;
const PATH_P1 = `${BASE_PATH.ACCOUNTS}/p1/accounts`;

const get_by_id = common.get_by_id(PATH_V1);
const find = common.find(PATH_V1);
const find_user = common.find(`${PATH_V1}/find`);
const find_current = common.find(`${PATH_V1}/current`);
const find_by = common.find_by(PATH_V1);
const create = common.create(PATH_P1);
const update = common.update(PATH_V1);
const remove = common.remove(PATH_V1);

// const find_user = (params) => {
//   if (!params) {
//     throw new Error('params is undefined');
//   }

//   return axios.create(AuthService.header()).get(`${PATH}/find`, { params });
// }

export default { get_by_id, find, find_user, find_current, find_by, create, update, remove };
