import { type as loadingTypes } from 'actions/globals/v1/loading.actions';

const initialState = {
  loading: false,
  error: null,
};

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
  case loadingTypes.ON_STARTED: {
    return {
      loading: true,
      error: null,
    };
  }
  case loadingTypes.ON_STOPPED: {
    return {
      loading: false,
      error: null,
    };
  }
  case loadingTypes.ON_SUCCESS: {
    return {
      loading: false,
      error: null,
    };
  }
  case loadingTypes.ON_FAILURE: {
    return {
      loading: false,
      error: data,
    };
  }
  default:
    return state;
  }
};

export default reducer;
