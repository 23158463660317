import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import api from 'generic/api/guests/v1';
import { toast } from 'generic/services/globals';
import { IButton } from 'components/buttons/v2';
import { Flex } from 'components/grids/v2';
import { ExtraList } from '../list/ExtraList';

import './RoomExtrasProducts.scss';

const RoomExtrasProducts = ({ booking }) => {
  const [products, set_products] = useState([...booking.products]);

  const on_create_select = () => {
    products.push({
      name: 'Otros',
      amount: 1,
      price: 0,
    });
    set_products([...products]);
  };

  const on_product_update = useCallback((index, data) => {
    products[index] = data;
    set_products([...products]);
    api.bookings.update({ _id: booking._id, products })
    .catch(toast.api_danger);
  }, [booking, products]);

  const on_product_delete = useCallback((index) => {
    products.splice(index, 1);
    set_products([...products]);
    api.bookings.update({ _id: booking._id, products })
    .catch(toast.api_danger);
  }, [booking, products]);

  return (
    <div>
      <Flex direction={Flex.V}>
        <Flex.Item>
          <h2>Otros</h2>
          <ExtraList
            items={products}
            on_update={on_product_update}
            on_delete={on_product_delete}
          />
        </Flex.Item>
        <Flex.End>
          <IButton label="Registrar productos" icon="checkmark" on_click={on_create_select} />
        </Flex.End>
      </Flex>
    </div>
  );
};

RoomExtrasProducts.propTypes = {
  booking: PropTypes.any.isRequired,
};

export { RoomExtrasProducts };
