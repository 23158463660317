import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import api from 'generic/api/guests/v1';
import { toast } from 'generic/services/globals';
import { ContentTitle } from 'components';
import { IButton } from 'components/buttons/v2';
import { useHistory } from 'react-router-dom';
import { Flex } from 'components/grids/v2';
import { LinearList } from 'components/lists/v1';
import { ADD_NEW_ITEM, ITEMS_HEADER, BASE_URL } from './constants';

const RoomTypes = ({ area }) => {
  const history = useHistory();
  const [items, set_items] = useState([]);

  const on_create = useCallback(() => {
    history.push(`${BASE_URL}/new`);
  }, [history]);

  const on_select = useCallback((group) => {
    history.push(`${BASE_URL}/${group._id}`);
  }, [history]);

  useEffect(() => {
    api.room_types.find({ area_id: area._id })
    .then(({ data }) => {
      set_items(data);
    })
    .catch(toast.api_danger);
  }, [area]);

  return (
    <Flex direction={Flex.V}>
      <ContentTitle config={ITEMS_HEADER} />
      <Flex.Item>
        <Flex.End>
          <IButton label={ADD_NEW_ITEM} icon="plus" on_click={on_create} />
        </Flex.End>
        <LinearList items={items} on_select={on_select} />
      </Flex.Item>
    </Flex>
  );
};

RoomTypes.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { RoomTypes };
