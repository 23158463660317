import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import api from 'generic/api/guests/v1';
import { toast } from 'generic/services/globals';
import { Flex } from 'components/grids/v4';
import { PrintInvoice } from './PrintInvoice';
import { PrintOptions } from './PrintOptions';

import './Invoices.scss';

const Invoices = () => {
  const { sale_id } = useParams();
  const [item, set_item] = useState(null);

  useEffect(() => {
    api.sales.get_by_id(sale_id)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [sale_id]);

  return (
    <Flex direction={Flex.H}>
      <Flex.Item flex={4}>
        {item && <PrintInvoice sale={item} />}
      </Flex.Item>
      <Flex.Item flex={3} class_name="invoice-print">
        {item && <PrintOptions sale={item} />}
      </Flex.Item>
    </Flex>
  );
};

export { Invoices };
