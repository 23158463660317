import React, { useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import api from 'generic/api/guests/v1';
import { toast } from 'generic/services/globals';

import { IRoomButton } from '../helper/IRoomButton';
import { DescriptionRequest } from '../helper/models/DescriptionRequest';

const RoomState = ({ area, room, set_room, item, configuration }) => {
  const { account } = useSelector((state) => state.auth);

  const on_description_callback = useCallback((result) => {
    const new_item = {
      start: moment(),
      end: moment(),
      area_id: area._id,
      room_id: room._id,
      requester_id: account._id,
      requester_name: `${account.username}`,
      completer_id: null,
      completer_name: null,
      description: result.description,
      type: item.type,
      active: true,
    };

    api.rooms.create_room_state(room._id, new_item)
    .then(({ data }) => {
      set_room({ ...room, [configuration._id]: data._id });
    })
    .catch(toast.api_danger);
  }, [area, account, room, set_room, item, configuration]);

  const on_complete_state = useCallback(() => {
    const new_item = {
      end: moment(),
      completer_id: account._id,
      completer_name: `${account.username}`,
      active: false,
      type: item.type,
    };
    api.rooms.update_room_state(room._id, new_item)
    .then(() => {
      set_room({ ...room, [configuration._id]: null });
    })
    .catch(toast.api_danger);
  }, [account, room, set_room, configuration, item]);

  if (room[configuration._id]) {
    return (
      <IRoomButton label={configuration.complete_label} icon="checkmark" color="gray" on_click={on_complete_state} />
    );
  }

  return (
    <DescriptionRequest
      label={configuration.initial_label}
      data={item}
      on_click={on_description_callback}
    />
  );
};

RoomState.propTypes = {
  area: PropTypes.any.isRequired,
  room: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
  configuration: PropTypes.any.isRequired,
  set_room: PropTypes.func.isRequired,
};

export { RoomState };
