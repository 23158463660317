import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import api from 'generic/api/globals/v1';
import { toast } from 'generic/services/globals';
import { IButton } from 'components/buttons/v2';
import { LinearListOptionItem } from 'components/lists/v1';

const Administrator = ({ item, on_remove }) => {
  const [account, set_account] = useState({
    _id: '',
    name: '',
    description: '',
  });

  const render_options = (data) => (
    <IButton label="eliminar" icon="cancel" on_click={() => on_remove(data)} />
  );

  const on_select = useCallback((data) => {
    /* eslint-disable no-console */
    console.log(data);
  }, []);

  useEffect(() => {
    api.accounts.get_by_id(item._id)
    .then(({ data }) => {
      set_account({
        _id: data._id,
        name: data.email,
        description: `${data.first_name} ${data.last_name}`,
      });
    })
    .catch(toast.api_danger);
  }, [item]);

  return (
    <LinearListOptionItem item={account} options={render_options} on_select={on_select} />
  );
};

Administrator.propTypes = {
  item: PropTypes.any.isRequired,
  on_remove: PropTypes.func.isRequired,
};

export { Administrator };
