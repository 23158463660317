import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'components/grids/v3';

const ListItem = ({ item, on_select }) => {
  const on_click = (e) => {
    e.stopPropagation();
    on_select(item);
  };

  const render_customer = (customer) => (
    customer.is_person ? `${customer.first_name} ${customer.last_name}` : customer.company_name
  );

  return (
    <Flex.Scrap class_name="report-list-item" onClick={on_click}>
      <Flex direction={Flex.H}>
        <Flex.Item flex={2}>
          {`${item.booking.start.format('MM/DD/YYYY')}`}
        </Flex.Item>
        <Flex.Item flex={2}>
          {`${item.booking.end.format('MM/DD/YYYY')}`}
        </Flex.Item>
        <Flex.Item flex={2}>
          {item.room_type.name}
        </Flex.Item>
        <Flex.Item flex={1}>
          {item.room.name}
        </Flex.Item>
        <Flex.Item flex={2}>
          {item.customer.customer_index}
        </Flex.Item>
        <Flex.Item flex={4}>
          {render_customer(item.customer)}
        </Flex.Item>
      </Flex>
    </Flex.Scrap>
  );
};

ListItem.propTypes = {
  item: PropTypes.any.isRequired,
  on_select: PropTypes.func.isRequired,
};

export { ListItem };
