import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Search, Grid } from 'semantic-ui-react';

import { toast } from 'generic/services/globals';
import { Button } from 'components/buttons/v2';
import { Flex } from 'components/grids/v2';
import api from 'generic/api/accounts/v1';
import { useFormReducer } from 'generic/functions/globals/reducer';
import { LinearList } from 'components/lists/v1';

const CheckoutCustomerModal = ({ open, set_open, visitors, customer, set_customer }) => {
  const [loading, set_loading] = useState(false);
  const [dropdown, set_dropdown] = useState(true);
  const [results, set_results] = useState([]);
  const [value, setValue] = useState('');
  const {
    item,
    set_item,
    on_string_change,
  } = useFormReducer({
    is_person: true,
    apps: {
      guests: {
        authorized: true,
        notification: true,
      },
    },
  });

  const on_is_person_change = (data) => {
    set_item({ ...item, is_person: data });
  };

  const save_customer = (data) => {
    set_customer(data);
    set_loading(false);
    set_open(false);
    setValue('');
    set_item({
      is_person: true,
      apps: {
        guests: {
          authorized: true,
          notification: true,
        },
      },
    });
  };

  const handle_submit = () => {
    set_loading(true);
    if (item._id) {
      const update_customer = {
        customer_index: item.customer_index,
        customer_name: item.customer_name,
        email: item.email,
        phone: item.phone,
        is_person: item.is_person,
      };

      if (item.is_person) {
        update_customer.first_name = item.first_name;
        update_customer.last_name = item.last_name;
      } else {
        update_customer.company_name = item.company_name;
      }

      api.customers.update(item._id, update_customer)
      .then(({ data }) => {
        save_customer(data);
      })
      .catch(toast.api_danger);
    } else {
      const new_customer = { ...item };

      if (new_customer.is_person) {
        new_customer.first_name = new_customer.first_name || 'Sin';
        new_customer.last_name = new_customer.last_name || 'nombre';
      } else {
        new_customer.company_name = new_customer.company_name
          ? new_customer.company_name
          : new_customer.customer_name;
      }

      api.customers.create(new_customer)
      .then(({ data }) => {
        save_customer(data);
      })
      .catch(toast.api_danger);
    }
  };

  const render_visitors = (items) => (
    items.map((list_item) => list_item.customer).map((list_item) => ({
      _id: list_item._id,
      name: list_item.is_person ? `${list_item.first_name} ${list_item.last_name}` : list_item.company_name,
      description: list_item.customer_index,
      visitor: list_item,
    }))
  );

  const on_select_visitor = useCallback(({ visitor }) => {
    set_item(visitor);
  }, [set_item]);

  const on_cancel = () => {
    set_open(false);
    set_item({
      is_person: true,
      apps: {
        guests: {
          authorized: true,
          notification: true,
        },
      },
    });
    set_customer(null);
    setValue('');
  };

  const result_renderer = ({
    _id,
    customer_index,
    customer_name,
    is_person,
    company_name,
    first_name,
    last_name,
  }) => (
    <div key={_id} className="search-result-item">
      <h5>{`${customer_index} - ${customer_name}`}</h5>
      <small>{`${is_person ? `${first_name} ${last_name}` : `${company_name}`} - ${is_person ? 'persona' : 'empresa'}`}</small>
    </div>
  );

  const handle_search_change = useCallback((e, result) => {
    e.preventDefault();
    setValue(result.value);
    api.customers.find_by({ page: 1, limit: 10 }, { customer_index: result.value })
    .then(({ data }) => {
      set_results(data);
    })
    .catch(toast.api_danger);
  }, []);

  useEffect(() => {
    if (!customer) {
      return;
    }

    set_item(customer);
  }, [set_item, customer]);

  useEffect(() => {
    set_loading(false);
  }, []);

  const render_client_form = () => (
    <Form
      inverted
      size="huge"
      loading={loading}
      onSubmit={(e) => handle_submit(e)}
    >
      {
        item.is_person && (
          <>
            <Form.Group widths="equal">
              <Form.Field>
                <label>CI</label>
                <input
                  name="customer_index"
                  value={item.customer_index}
                  placeholder="CI"
                  required
                  onChange={on_string_change}
                  onInvalid={(e) => e.target.setCustomValidity('Agregar el CI del cliente')}
                  onInput={(e) => e.target.setCustomValidity('')}
                />
              </Form.Field>
              <Form.Field>
                <label>NOMBRE PARA LA FACTURA</label>
                <input
                  name="customer_name"
                  value={item.customer_name}
                  placeholder="NOMBRE PARA LA FACTURA"
                  required
                  onChange={on_string_change}
                  onInvalid={(e) => e.target.setCustomValidity('Agregar la razon social del cliente')}
                  onInput={(e) => e.target.setCustomValidity('')}
                />
              </Form.Field>
            </Form.Group>
            {
              dropdown && (
                <Form.Group widths="equal">
                  <Form.Field width={6}>
                    <label>Nombre</label>
                    <input name="first_name" value={item.first_name} placeholder="NOMBRE" onChange={on_string_change} />
                  </Form.Field>
                  <Form.Field width={10}>
                    <label>Apellidos</label>
                    <input name="last_name" value={item.last_name} placeholder="APELLIDOS PATERNO MATERNO" onChange={on_string_change} />
                  </Form.Field>
                </Form.Group>
              )
            }
          </>
        )
      }
      {
        !item.is_person && (
          <>
            <Form.Group widths="equal">
              <Form.Field>
                <label>NIT</label>
                <input
                  name="customer_index"
                  value={item.customer_index}
                  placeholder="NIT"
                  required
                  onChange={on_string_change}
                  onInvalid={(e) => e.target.setCustomValidity('Agregar la razon social del cliente')}
                  onInput={(e) => e.target.setCustomValidity('')}
                />
              </Form.Field>
              <Form.Field>
                <label>NOMBRE PARA LA FACTURA</label>
                <input
                  name="customer_name"
                  value={item.customer_name}
                  placeholder="NOMBRE PARA LA FACTURA"
                  required
                  onChange={on_string_change}
                  onInvalid={(e) => e.target.setCustomValidity('Agregar la razon social del cliente')}
                  onInput={(e) => e.target.setCustomValidity('')}
                />
              </Form.Field>
            </Form.Group>
            {
              dropdown && (
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Nombre de la empresa</label>
                    <input name="company_name" value={item.company_name} placeholder="Nombre de la Empresa" onChange={on_string_change} />
                  </Form.Field>
                </Form.Group>
              )
            }
          </>
        )
      }
      {
        dropdown && (
          <Form.Group widths="equal">
            <Form.Field>
              <label>Correo electronico</label>
              <input name="email" value={item.email} placeholder="Correo electronico" onChange={on_string_change} />
            </Form.Field>
            <Form.Field>
              <label>Numero de celular</label>
              <input name="phone" value={item.phone} placeholder="Numero de celular" onChange={on_string_change} />
            </Form.Field>
          </Form.Group>
        )
      }
      {
        dropdown
          ? <div onClick={() => set_dropdown(false)}>MOSTRAR MENOS</div>
          : <div onClick={() => set_dropdown(true)}>MOSTRAR MAS</div>
      }
      <div className="visitor-search-form">
        <Button label="Cancelar" size="huge" on_click={on_cancel} />
        <Button label="Seleccionar cliente" submit />
      </div>
    </Form>
  );

  return (
    <Modal
      basic
      onClose={() => set_open(false)}
      onOpen={() => set_open(true)}
      open={open}
    >
      <Modal.Content className="customer-serach-modal">
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column width={16}>
              <h2>Visitantes</h2>
              <LinearList
                items={render_visitors(visitors)}
                on_select={on_select_visitor}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <Search
                autoFocus
                size="huge"
                loading={loading}
                placeholder="Buscar por CI o NIT"
                resultRenderer={result_renderer}
                onResultSelect={(e, data) => {
                  e.stopPropagation();
                  set_item(data.result);
                }}
                onSearchChange={handle_search_change}
                results={results}
                value={value}
              />
            </Grid.Column>
            <Grid.Column floated="right" width={9}>
              <Flex.End>
                <Button label="Persona" color={item.is_person ? 'blue' : ''} on_click={() => on_is_person_change(true)} />
                <Button label="Institución" color={item.is_person ? '' : 'blue'} on_click={() => on_is_person_change(false)} />
              </Flex.End>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {render_client_form()}
      </Modal.Content>
    </Modal>
  );
};

CheckoutCustomerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  set_open: PropTypes.func.isRequired,
  customer: PropTypes.any,
  visitors: PropTypes.arrayOf(PropTypes.any).isRequired,
  set_customer: PropTypes.func.isRequired,
};

CheckoutCustomerModal.defaultProps = {
  customer: {
    index: '',
  },
};

export { CheckoutCustomerModal };
