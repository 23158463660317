import React, { useCallback, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import api from 'generic/api/guests/v1';
import { Header, EmptyInfo } from 'components/titles/v2';
import { toast } from 'generic/services/globals';
import { Flex, Container } from 'components/grids/v3';

import { List } from './list/List';
import { generate_file } from '../utils/excel';
import { DateSelector } from '../utils/DateSelector';

const VisitorsHistory = ({ area }) => {
  const [items, set_items] = useState([]);

  const parse_data = () => (
    items.map((item) => ({
      entrada: moment(item.start).format('MM/DD/YYYY'),
      salida: moment(item.end).format('MM/DD/YYYY'),
      tipo: item.room_type_name,
      habitacion: item.room_name,
      ci: item.customer_index,
      nombre: item.customer_name,
    }))
  );

  const on_search = useCallback((range) => {
    api.rooms.find_visitors({ area_id: area._id, ...range })
    .then(({ data }) => {
      set_items(data);
    })
    .catch(toast.api_danger);
  }, [area]);

  const on_download = () => {
    generate_file(parse_data(), 'people', 'visitors');
  };

  return (
    <Flex direction={Flex.V}>
      <Container width="1200px">
        <Header title="Historial de visitantes" />
        <DateSelector on_search={on_search} on_download={on_download} />
        <Flex.Item>
          {(items.length === 0) && <EmptyInfo description="No existe visitantes en el hotel" />}
          {(items.length > 0) && <List items={items} on_select={() => {}} />}
        </Flex.Item>
      </Container>
    </Flex>
  );
};

VisitorsHistory.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { VisitorsHistory };
