export const types = {
  SENSORS_SET: 'sensors-set',
  SENSORS_SET_ONE: 'sensors-set-one',
  SENSORS_UPDATE: 'sensors-update',
  SENSORS_REMOVE: 'sensors-remove',
};

const set = (data) => ({
  type: types.SENSORS_SET,
  data,
});

const set_one = (data) => ({
  type: types.SENSORS_SET_ONE,
  data,
});

const update = (data) => ({
  type: types.SENSORS_UPDATE,
  data,
});

const remove = (data) => ({
  type: types.SENSORS_REMOVE,
  data,
});

export default { set, set_one, update, remove };
