import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import './ProductListItem.scss';

const ProductListItem = ({ data, on_update }) => {
  const [price, set_price] = useState(data.price);

  const on_local_number_change = (event) => {
    const aux_price = parseInt(event.target.value, 10);
    on_update(data.room_id, aux_price);
    set_price(aux_price);
  };

  return (
    <div className="linear-list">
      <div className="name">{data.name}</div>
      <div className="data">{data.amount}</div>
      <div className="data">{`${data.original} Bs.`}</div>
      <div className="field">
        <Input placeholder="Precio...">
          <input name="price" type="number" placeholder="Precio" value={price} onChange={on_local_number_change} />
        </Input>
      </div>
      <div className="data">{`${data.amount * price} Bs.`}</div>
    </div>
  );
};

ProductListItem.propTypes = {
  on_update: PropTypes.func,
  amount: PropTypes.number.isRequired,
  data: PropTypes.any.isRequired,
};

ProductListItem.defaultProps = {
  on_update: () => {},
};

export { ProductListItem };
