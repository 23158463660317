import { store } from 'reducers';
import actions from 'actions/globals/v1';

const resolve = () => (Promise.resolve());
const start = (data) => {
  store.dispatch(actions.loading.start());
  return data;
};

const stop = (data) => {
  store.dispatch(actions.loading.stop());
  return data;
};

const error = (err) => {
  store.dispatch(actions.loading.stop());
  throw err;
};

export default { resolve, start, stop, error };
