import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { HImage } from 'components';
import { useSelector } from 'react-redux';

import { CheckoutInvoiceCart } from './CheckoutInvoiceCart';

const LetterReceipt = ({ sale }) => {
  const { setting } = useSelector((state) => state.area);

  return (
    <div id="checkout-invoice" style={{ width: '770px', background: '#ffffff', fontFamily: 'Verdana, Sans-serif', fontSize: '11px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', overflowY: 'auto', color: '#000000', padding: '10px', lineHeight: 1 }}>
        <div style={{ flex: 4 }}>
          <div style={{ textAlign: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <HImage image={setting.image_id} width="80px" quality={80} />
              </div>
              <div style={{ flex: 4 }}>
                <h3 style={{ margin: '0px' }}>
                  {setting.area_name}
                </h3>
                <div>
                  {`De: ${setting.area_owner}`}
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div>
              {setting.area_address}
            </div>
            <div>
              {setting.area_phone}
            </div>
            <div>
              {setting.area_city}
            </div>
          </div>
        </div>
        <div style={{ flex: 3 }}>
          <div style={{ textAlign: 'center', fontSize: '30px', fontWeight: 'bold' }}>
            RECIBO
          </div>
        </div>
        <div style={{ flex: 4 }}>
          <div style={{ textAlign: 'center', marginBottom: '10px' }}>
            <div>
              {`NIT: ${setting.area_nit}`}
            </div>
            <div>
              {`Actividad Económica: ${setting.area_activity}`}
            </div>
            <div style={{ fontWeight: 'bold' }}>
              ORIGINAL
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: '#ffffff', overflowY: 'auto', color: '#000000', padding: '0px 10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <div>
              {`FECHA: ${moment(sale.created).format('DD/MM/YYYY h:mm A')}`}
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div>
              {`NIT/CI: ${sale.customer.customer_index}`}
            </div>
          </div>
        </div>
        <div>
          {`SEÑOR(ES): ${sale.customer.customer_name}`}
        </div>
        <CheckoutInvoiceCart sale={sale} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', overflowY: 'auto', color: '#000000', padding: '0px 10px' }}>
        <div style={{ flex: 8 }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 2, fontSize: '10px' }}>
              {`Emitido por: ${sale.account.username}`}
            </div>
            <div style={{ flex: 2, fontSize: '8px', textAlign: 'right' }}>
              hotel.unboliviano.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LetterReceipt.propTypes = {
  sale: PropTypes.any.isRequired,
};

export { LetterReceipt };
