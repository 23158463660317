import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Search, Grid } from 'semantic-ui-react';

import { toast } from 'generic/services/globals';
import { Button } from 'components/buttons/v2';
import api from 'generic/api/accounts/v1';
import { useFormReducer } from 'generic/functions/globals/reducer';

import './ClientSearchModal.scss';

const VisitorSearchModal = ({ open, set_open, set_customer }) => {
  const [loading, set_loading] = useState(false);
  const [results, set_results] = useState([]);
  const [value, setValue] = useState('');
  const {
    item,
    set_item,
    on_string_change,
  } = useFormReducer({
    is_person: true,
    apps: {
      guests: {
        authorized: true,
        notification: true,
      },
    },
  });

  const save_customer = (data) => {
    set_customer(data);
    set_loading(false);
    set_open(false);
    setValue('');
    set_item({
      is_person: true,
      apps: {
        guests: {
          authorized: true,
          notification: true,
        },
      },
    });
  };

  const handle_submit = () => {
    set_loading(true);

    if (item._id) {
      api.customers.update(item._id, {
        customer_index: item.customer_index,
        company_name: item.company_name,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        phone: item.phone,
      })
      .then(({ data }) => {
        save_customer(data);
      })
      .catch(toast.api_danger);
    } else {
      const new_customer = { ...item, customer_name: item.last_name };

      api.customers.create(new_customer)
      .then(({ data }) => {
        save_customer(data);
      })
      .catch(toast.api_danger);
    }
  };

  const on_cancel = () => {
    set_open(false);
  };

  const result_renderer = ({
    _id,
    customer_index,
    customer_name,
    is_person,
    company_name,
    first_name,
    last_name,
  }) => (
    <div key={_id} className="search-result-item">
      <h5>{`${customer_index} - ${is_person ? `${first_name} ${last_name}` : `${company_name}`}`}</h5>
      <small>{`${customer_name} - ${is_person ? 'persona' : 'empresa'}`}</small>
    </div>
  );

  const handle_search_change = useCallback((e, result) => {
    e.preventDefault();
    setValue(result.value);
    api.customers.find_by({ page: 1, limit: 10 }, { customer_index: result.value })
    .then(({ data }) => {
      set_results(data);
    })
    .catch(toast.api_danger);
  }, []);

  const render_client_form = () => (
    <Form
      inverted
      size="huge"
      loading={loading}
      onSubmit={(e) => handle_submit(e)}
    >
      <Form.Group widths="equal">
        <Form.Field width={4}>
          <label>CI</label>
          <input
            name="customer_index"
            value={item.customer_index}
            placeholder="CI"
            required
            onChange={on_string_change}
            onInvalid={(e) => e.target.setCustomValidity('Agregar el CI del visitante')}
            onInput={(e) => e.target.setCustomValidity('')}
          />
        </Form.Field>
        <Form.Field width={5}>
          <label>Nombre</label>
          <input
            name="first_name"
            value={item.first_name}
            placeholder="NOMBRE"
            required
            onChange={on_string_change}
            onInvalid={(e) => e.target.setCustomValidity('Agregar el nombre del visitante')}
            onInput={(e) => e.target.setCustomValidity('')}
          />
        </Form.Field>
        <Form.Field width={7}>
          <label>Apellidos</label>
          <input
            name="last_name"
            value={item.last_name}
            placeholder="APELLIDOS PATERNO MATERNO"
            required
            onChange={on_string_change}
            onInvalid={(e) => e.target.setCustomValidity('Agregar los dos apellidos')}
            onInput={(e) => e.target.setCustomValidity('')}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Correo electronico</label>
          <input name="email" value={item.email} placeholder="Correo electronico" onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Numero de celular</label>
          <input name="phone" value={item.phone} placeholder="Numero de celular" onChange={on_string_change} />
        </Form.Field>
      </Form.Group>
      <div className="visitor-search-form">
        <Button label="Cancelar" size="huge" on_click={on_cancel} />
        <Button label="Seleccionar visitante" submit />
      </div>
    </Form>
  );

  return (
    <Modal
      basic
      onClose={() => set_open(false)}
      onOpen={() => set_open(true)}
      open={open}
    >
      <Modal.Content className="visitor-serach-modal">
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column width={10}>
              <Search
                autoFocus
                size="huge"
                loading={loading}
                placeholder="Buscar por CI o NIT"
                resultRenderer={result_renderer}
                onResultSelect={(e, data) => {
                  e.stopPropagation();
                  set_item(data.result);
                }}
                onSearchChange={handle_search_change}
                results={results}
                value={value}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {render_client_form()}
      </Modal.Content>
    </Modal>
  );
};

VisitorSearchModal.propTypes = {
  open: PropTypes.bool.isRequired,
  set_open: PropTypes.func.isRequired,
  set_customer: PropTypes.func.isRequired,
};

VisitorSearchModal.defaultProps = {
};

export { VisitorSearchModal };
