import { types } from 'actions/guests/main.actions';

const initial_state = {
  booking: null,
  rooms: [],
  room_types: [],
  main: [],
};

const reducer = (state = initial_state, { type, data }) => {
  switch (type) {
  case types.MAIN_SET: {
    return { ...state, main: [...data] };
  }
  case types.MAIN_SET_ONE: {
    return { ...state, main: [data, ...state.main] };
  }
  case types.MAIN_UPDATE: {
    const main = state.main.map((item) => (item._id === data._id ? { ...data } : item));
    return { ...state, main };
  }
  case types.MAIN_REMOVE: {
    const main = state.main.filter((item) => item._id !== data._id);
    return { ...state, main };
  }
  case types.MAIN_SET_BOOKING: {
    return { ...state, booking: data };
  }
  case types.MAIN_SET_ROOMS: {
    return { ...state, rooms: [...data] };
  }
  case types.MAIN_SET_ROOM_TYPES: {
    return { ...state, room_types: [...data] };
  }
  default:
    return state;
  }
};

export default reducer;
