import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, TextArea } from 'semantic-ui-react';

import { useFormReducer } from 'generic/functions/globals/reducer';
import { IButton } from 'components/buttons/v2';
import { IRoomButton } from '../IRoomButton';

const DescriptionRequest = ({ label, data, on_click }) => {
  const [open, set_open] = useState(false);
  const [form, setFrom] = useState(null);
  const {
    item,
    on_string_change,
  } = useFormReducer({
    description: data.description,
  });

  const handle_submit = () => {
    set_open(false);
    on_click(item);
  };

  return (
    <>
      <Modal
        onClose={() => set_open(false)}
        onOpen={() => set_open(true)}
        open={open}
        size="small"
      >
        <Modal.Header>Añadir descripción</Modal.Header>
        <Modal.Content>
          <Form onSubmit={(e) => handle_submit(e)} ref={(ref) => setFrom(ref)}>
            <TextArea name="description" value={item.description} placeholder="Descripción" onChange={on_string_change} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <IButton label="Cancel" icon="delete" on_click={() => set_open(false)} />
          <IButton label="Enviar" icon="checkmark" color="blue" on_click={() => form.handleSubmit()} />
        </Modal.Actions>
      </Modal>
      <IRoomButton label={label} icon="checkmark" color="warn" on_click={() => set_open(true)} />
    </>
  );
};

DescriptionRequest.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired,
  on_click: PropTypes.func.isRequired,
};

export { DescriptionRequest };
