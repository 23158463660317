import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Select } from 'semantic-ui-react';

import { GENERIC } from 'consts';
import api from 'generic/api/guests/v1';
import { toast } from 'generic/services/globals';
import { generic } from 'generic/functions/globals';
import { Flex } from 'components/grids/v2';
import { useFormReducer } from 'generic/functions/globals/reducer';
import { RemoveConfirmation } from 'components/generics/v1';
import { UImage, ContentTitle } from 'components';
import { IButton } from 'components/buttons/v2';
import { ITEM_HEADER } from './constants';

const RoomEdit = ({ area }) => {
  const { room_id } = useParams();
  const history = useHistory();
  const {
    item,
    set_item,
    on_string_change,
    on_image_change,
  } = useFormReducer({ area_id: area._id, maintenance_id: null, housekeeping_id: null });
  const [is_new] = useState(room_id === undefined);
  const [room_types, set_room_types] = useState([]);

  const on_submit = (e) => {
    e.preventDefault();
    if (is_new) {
      api.rooms.create(item)
      .then(() => {
        history.push(`${GENERIC.APP_BASE_URL}/rooms`);
      })
      .catch(toast.api_danger);
    } else {
      api.rooms.update(item)
      .then(() => {
        history.push(`${GENERIC.APP_BASE_URL}/rooms`);
      })
      .catch(toast.api_danger);
    }
  };

  const on_remove_callback = useCallback(() => {
    api.rooms.remove(item)
    .then(() => {
      history.push(`${GENERIC.APP_BASE_URL}/rooms`);
    })
    .catch(toast.api_danger);
  }, [history, item]);

  const on_type_change = (event, element) => {
    set_item({ ...item, room_type_id: element.value });
  };

  useEffect(() => {
    api.room_types.find({ area_id: area._id })
    .then(({ data }) => {
      set_room_types(data);
    })
    .catch(toast.api_danger);

    if (is_new) return;

    api.rooms.get_by_id(room_id)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [is_new, room_id, area, set_item]);

  return (
    <>
      <ContentTitle config={ITEM_HEADER} />
      <Form onSubmit={on_submit} inverted>
        <Form.Field>
          <label>Numero de habitación</label>
          <input name="name" placeholder="#" value={item.name} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Almacen</label>
          <Select placeholder="Tipo" value={item.room_type_id} onChange={on_type_change} options={generic.get_options(room_types, 'name')} />
        </Form.Field>
        <Form.Field>
          <label>Caracteristicas</label>
          <input name="attribute" placeholder="Caracteristicas" value={item.attribute} onChange={on_string_change} />
        </Form.Field>
        {/* <Form.Field>
          <label>Color</label>
          <input
            name="color"
            type="color"
            placeholder="Color"
            value={item.color} onChange={on_string_change} />
        </Form.Field> */}
        <Form.Field>
          <label>Descripción</label>
          <textarea name="description" placeholder="Descripción" value={item.description} onChange={on_string_change} />
        </Form.Field>
        <UImage image={item.image_id} height="150px" quality={1080} on_callback={on_image_change} />
        <Flex.End>
          <RemoveConfirmation label="Eliminar habitación" on_click={on_remove_callback} />
          {is_new && <IButton label="Crear habitación" icon="checkmark" submit />}
          {!is_new && <IButton label="Actualizar habitación" icon="checkmark" submit />}
        </Flex.End>
      </Form>
    </>
  );
};

RoomEdit.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { RoomEdit };
